import { IntegrationEnum, Maybe } from 'gql/graphql';
import { GithubIcon, HubSpotTinyIcon } from 'lib/Integrations';
import { useTranslations } from 'next-intl';
import { FC } from 'react';
import Typography from './Typography';

interface IIntegrations {
  type?: Maybe<IntegrationEnum>;
}

const Integrations: FC<IIntegrations> = ({ type }) => {
  const t = useTranslations();

  if (IntegrationEnum.Hubspot === type)
    return (
      <div className="flex">
        <HubSpotTinyIcon className="h-6 w-6 scale-[1.2] pt-1" />
        <Typography
          variant="medium4"
          className="!m-0 !w-fit text-left text-tertiary-100 dark:text-white"
        >
          {t('dashboard.tr.translation.details.integrations.hubspot')}
        </Typography>
      </div>
    );

  if (IntegrationEnum.Github === type)
    return (
      <div className="flex">
        <span className="relative flex h-6 w-6 items-center justify-normal pt-1">
          <GithubIcon className="absolute -left-6 top-[-22px] scale-[0.3]" />
        </span>
        <Typography
          variant="medium4"
          className="!m-0 !w-fit text-left text-tertiary-100 dark:text-white"
        >
          {t('dashboard.tr.translation.details.integrations.github')}
        </Typography>
      </div>
    );

  return null;
};

export default Integrations;
