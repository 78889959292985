/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded date */
  ISO8601Date: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  /** Represents untyped JSON */
  JSON: any;
  Upload: any;
};

/** Autogenerated input type of AccountSetPassword */
export type AccountSetPasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  token: Scalars['String'];
};

/** Autogenerated return type of AccountSetPassword. */
export type AccountSetPasswordPayload = {
  __typename?: 'AccountSetPasswordPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of AddLanguage */
export type AddLanguageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  languageCode: Scalars['String'];
};

/** Autogenerated return type of AddLanguage. */
export type AddLanguagePayload = {
  __typename?: 'AddLanguagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of ApproveAndAssign */
export type ApproveAndAssignInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  glossaryId: Array<Scalars['ID']>;
  termId: Scalars['ID'];
};

/** Autogenerated return type of ApproveAndAssign. */
export type ApproveAndAssignPayload = {
  __typename?: 'ApproveAndAssignPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export enum AudienceEnum {
  Customers = 'customers',
  Employees = 'employees',
  Investors = 'investors',
  Public = 'public',
  Stakeholders = 'stakeholders',
  Suppliers = 'suppliers',
  Technical = 'technical'
}

/** Autogenerated input type of Become */
export type BecomeInput = {
  becomeId: Scalars['ID'];
  becomeType?: InputMaybe<BecomeTypeEnum>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of Become. */
export type BecomePayload = {
  __typename?: 'BecomePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
  user?: Maybe<User>;
};

export enum BecomeTypeEnum {
  Account = 'Account',
  User = 'User'
}

export type BillingAccount = {
  __typename?: 'BillingAccount';
  allowedClientCurencies?: Maybe<Array<Scalars['String']>>;
  automatedInvoicing: Scalars['Boolean'];
  balance: Scalars['Float'];
  billingInfo: BillingInfo;
  creditCard?: Maybe<CreditCard>;
  currency: CurrencyEnum;
  currencyConfirmedAt?: Maybe<Scalars['ISO8601DateTime']>;
  invoiceBalance: Scalars['Float'];
  invoiceDueDays?: Maybe<Scalars['Int']>;
};

export type BillingInfo = {
  __typename?: 'BillingInfo';
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  business: Scalars['Boolean'];
  businessName?: Maybe<Scalars['String']>;
  chamberOfCommerce?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  invoiceCcEmails: Array<Scalars['String']>;
  invoiceEmails: Array<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  taxid?: Maybe<Scalars['String']>;
};

export type BillingInfoInput = {
  address: Scalars['String'];
  business: Scalars['Boolean'];
  businessName: Scalars['String'];
  city: Scalars['String'];
  country: CountryCodeEnum;
  currency: Scalars['String'];
  firstName: Scalars['String'];
  invoiceCcEmails?: InputMaybe<Array<Scalars['String']>>;
  invoiceEmails: Array<Scalars['String']>;
  lastName: Scalars['String'];
  postalCode: Scalars['String'];
  taxid: Scalars['String'];
};

/** Autogenerated return type of BillingInfoUpdate. */
export type BillingInfoUpdatePayload = {
  __typename?: 'BillingInfoUpdatePayload';
  billingInfo?: Maybe<BillingInfo>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export enum BulkDownloadFormatEnum {
  File = 'file',
  Json = 'json',
  Plain = 'plain',
  Pully = 'pully'
}

export type BulkDownloadStatus = {
  __typename?: 'BulkDownloadStatus';
  completedTranslationRequests?: Maybe<Array<Scalars['ID']>>;
  file?: Maybe<Scalars['String']>;
  progress?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
};

export type Callback = {
  __typename?: 'Callback';
  callbackLogs: CallbackLogPage;
  createdAt: Scalars['ISO8601DateTime'];
  failedCount: Scalars['Int'];
  id: Scalars['ID'];
  kind: Scalars['String'];
  status: Scalars['String'];
  translationRequest: TranslationRequest;
  updatedAt: Scalars['ISO8601DateTime'];
  url: Scalars['String'];
};

export type CallbackCallbackLogsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  skipPagination?: InputMaybe<Scalars['Boolean']>;
};

export type CallbackLog = {
  __typename?: 'CallbackLog';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  response?: Maybe<Scalars['String']>;
  responseCode: Scalars['Int'];
  translationRequest: TranslationRequest;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type CallbackLogPage = {
  __typename?: 'CallbackLogPage';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  nodes: Array<CallbackLog>;
  nodesCount: Scalars['Int'];
  pagesCount: Scalars['Int'];
};

/** Autogenerated input type of CallbackRetry */
export type CallbackRetryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  translationRequestVersion: Scalars['ID'];
};

/** Autogenerated return type of CallbackRetry. */
export type CallbackRetryPayload = {
  __typename?: 'CallbackRetryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of ChangeDefaultLanguage */
export type ChangeDefaultLanguageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  languageCode: Scalars['String'];
};

/** Autogenerated return type of ChangeDefaultLanguage. */
export type ChangeDefaultLanguagePayload = {
  __typename?: 'ChangeDefaultLanguagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type ClientAccount = {
  __typename?: 'ClientAccount';
  allOrderableSourceLanguages?: Maybe<Array<Language>>;
  allOrderableTargetLanguages?: Maybe<Array<Language>>;
  allowedToHaveEditors: Scalars['Boolean'];
  allowedTypes?: Maybe<Array<Scalars['String']>>;
  developerLogs: DeveloperLogPage;
  features?: Maybe<Array<Feature>>;
  githubRepos?: Maybe<Array<GithubRepo>>;
  githubReposSingleToken: Scalars['Boolean'];
  glossaries?: Maybe<GlossaryPage>;
  googleIntegration?: Maybe<GoogleIntegration>;
  hubspotIntegration?: Maybe<HubspotIntegration>;
  id: Scalars['ID'];
  languageData?: Maybe<LanguageData>;
  languagePairs?: Maybe<Array<LanguagePair>>;
  members?: Maybe<Array<Member>>;
  name: Scalars['String'];
  orderableQualities?: Maybe<Array<Scalars['String']>>;
  paymentMethod: PaymentMethodEnum;
  styleGuides?: Maybe<Array<StyleGuide>>;
  summary?: Maybe<ClientAccountSummary>;
  supportedFormats?: Maybe<Array<Scalars['String']>>;
  unreadCommentCount?: Maybe<Scalars['Int']>;
  usedTargetLanguages?: Maybe<Array<Scalars['String']>>;
  users?: Maybe<Array<User>>;
  wouldNeedDetails: Scalars['Boolean'];
};

export type ClientAccountDeveloperLogsArgs = {
  fromDate?: InputMaybe<Scalars['ISO8601DateTime']>;
  level?: InputMaybe<DeveloperLogsLevelEnum>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  skipPagination?: InputMaybe<Scalars['Boolean']>;
  toDate?: InputMaybe<Scalars['ISO8601DateTime']>;
  type?: InputMaybe<DeveloperLogsTypeEnum>;
};

export type ClientAccountGlossariesArgs = {
  id?: InputMaybe<Scalars['ID']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  skipPagination?: InputMaybe<Scalars['Boolean']>;
};

export type ClientAccountStyleGuidesArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type ClientAccountSummaryArgs = {
  comments?: InputMaybe<CommentsFilterEnum>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']>;
  createdBefore?: InputMaybe<Scalars['ISO8601DateTime']>;
  editorId?: InputMaybe<Scalars['ID']>;
  fromDate?: InputMaybe<Scalars['ISO8601DateTime']>;
  proposedClientDeadlineAfter?: InputMaybe<Scalars['ISO8601DateTime']>;
  proposedClientDeadlineBefore?: InputMaybe<Scalars['ISO8601DateTime']>;
  quality?: InputMaybe<QualityEnum>;
  query?: InputMaybe<Scalars['String']>;
  showDuplicates?: InputMaybe<Scalars['Boolean']>;
  sourceLanguageCode?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<InputMaybe<TranslationRequestStatusFilterEnum>>>;
  targetLanguageCode?: InputMaybe<Scalars['String']>;
  toDate?: InputMaybe<Scalars['ISO8601DateTime']>;
};

export type ClientAccountSummary = {
  __typename?: 'ClientAccountSummary';
  ordered?: Maybe<SummaryStats>;
  savings?: Maybe<SummaryStats>;
  translated?: Maybe<SummaryStats>;
};

export type ClientAccountUpdateInput = {
  emailNotifications: EmailNotificationPreferencesInput;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  locale: Scalars['String'];
  timeZone: Scalars['String'];
};

export type ClientInvite = {
  __typename?: 'ClientInvite';
  clientAccount?: Maybe<ClientAccount>;
  redirectTo?: Maybe<Scalars['String']>;
  user: User;
};

/** Autogenerated input type of ColumnCharLimit */
export type ColumnCharLimitInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  columnName: Scalars['String'];
  limit: Scalars['Int'];
  translationRequestId: Scalars['ID'];
};

/** Autogenerated return type of ColumnCharLimit. */
export type ColumnCharLimitPayload = {
  __typename?: 'ColumnCharLimitPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type Comment = {
  __typename?: 'Comment';
  clientAccount: ClientAccount;
  createdAt: Scalars['ISO8601DateTime'];
  flagged: Scalars['Boolean'];
  id: Scalars['ID'];
  isCorrection: Scalars['Boolean'];
  read: Scalars['Boolean'];
  refs: Array<Scalars['String']>;
  sourceChunkIds: Array<Scalars['ID']>;
  task: Task;
  text?: Maybe<Scalars['String']>;
  translationRequest: TranslationRequest;
  translatorAccount?: Maybe<TranslatorAccount>;
  updatedAt: Scalars['ISO8601DateTime'];
  writtenByClient: Scalars['Boolean'];
};

/** Autogenerated input type of CommentCreate */
export type CommentCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  sentences?: InputMaybe<Array<Scalars['String']>>;
  taskId: Scalars['ID'];
  text: Scalars['String'];
};

/** Autogenerated return type of CommentCreate. */
export type CommentCreatePayload = {
  __typename?: 'CommentCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  comment?: Maybe<Comment>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export enum CommentsFilterEnum {
  Pending = 'pending',
  With = 'with',
  Without = 'without'
}

/** Autogenerated input type of CommentsRead */
export type CommentsReadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  taskId: Scalars['ID'];
};

/** Autogenerated return type of CommentsRead. */
export type CommentsReadPayload = {
  __typename?: 'CommentsReadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of ConfirmEmailChange */
export type ConfirmEmailChangeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  code: Scalars['String'];
};

/** Autogenerated return type of ConfirmEmailChange. */
export type ConfirmEmailChangePayload = {
  __typename?: 'ConfirmEmailChangePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
  user?: Maybe<User>;
};

/** Autogenerated input type of ConfirmSignup */
export type ConfirmSignupInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  confirmationCode: Scalars['String'];
  email: Scalars['String'];
};

/** Autogenerated return type of ConfirmSignup. */
export type ConfirmSignupPayload = {
  __typename?: 'ConfirmSignupPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export enum ContentSourceEnum {
  Copy = 'copy',
  UploadedFileId = 'uploaded_file_id'
}

/** Autogenerated input type of ContextLibraryDestroy */
export type ContextLibraryDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  contextId: Scalars['ID'];
};

/** Autogenerated return type of ContextLibraryDestroy. */
export type ContextLibraryDestroyPayload = {
  __typename?: 'ContextLibraryDestroyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of ContextLibraryFileUpload */
export type ContextLibraryFileUploadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  file: Scalars['Upload'];
  tags?: InputMaybe<Array<Scalars['String']>>;
};

/** Autogenerated return type of ContextLibraryFileUpload. */
export type ContextLibraryFileUploadPayload = {
  __typename?: 'ContextLibraryFileUploadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  file?: Maybe<ContextLibraryUploadedFileType>;
  success: Scalars['Boolean'];
  tags?: Maybe<Array<Scalars['String']>>;
};

export type ContextLibraryTagType = {
  __typename?: 'ContextLibraryTagType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Autogenerated input type of ContextLibraryUpdate */
export type ContextLibraryUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  contextId: Scalars['ID'];
  filename?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
};

/** Autogenerated return type of ContextLibraryUpdate. */
export type ContextLibraryUpdatePayload = {
  __typename?: 'ContextLibraryUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  file?: Maybe<ContextLibraryUploadedFileType>;
  success: Scalars['Boolean'];
};

export enum ContextLibraryUploadedFileSortAttributeEnum {
  CreatedAt = 'created_at',
  Filename = 'filename',
  Filesize = 'filesize'
}

export type ContextLibraryUploadedFileType = {
  __typename?: 'ContextLibraryUploadedFileType';
  createdAt: Scalars['ISO8601DateTime'];
  errorMessage?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  filesize?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  processed?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<ContextLibraryTagType>>;
};

export type ContextLibraryUploadedFileTypePage = {
  __typename?: 'ContextLibraryUploadedFileTypePage';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  nodes: Array<ContextLibraryUploadedFileType>;
  nodesCount: Scalars['Int'];
  pagesCount: Scalars['Int'];
};

export enum CountryCodeEnum {
  Ad = 'AD',
  Ae = 'AE',
  Af = 'AF',
  Ag = 'AG',
  Ai = 'AI',
  Al = 'AL',
  Am = 'AM',
  Ao = 'AO',
  Aq = 'AQ',
  Ar = 'AR',
  As = 'AS',
  At = 'AT',
  Au = 'AU',
  Aw = 'AW',
  Ax = 'AX',
  Az = 'AZ',
  Ba = 'BA',
  Bb = 'BB',
  Bd = 'BD',
  Be = 'BE',
  Bf = 'BF',
  Bg = 'BG',
  Bh = 'BH',
  Bi = 'BI',
  Bj = 'BJ',
  Bl = 'BL',
  Bm = 'BM',
  Bn = 'BN',
  Bo = 'BO',
  Bq = 'BQ',
  Br = 'BR',
  Bs = 'BS',
  Bt = 'BT',
  Bv = 'BV',
  Bw = 'BW',
  By = 'BY',
  Bz = 'BZ',
  Ca = 'CA',
  Cc = 'CC',
  Cd = 'CD',
  Cf = 'CF',
  Cg = 'CG',
  Ch = 'CH',
  Ci = 'CI',
  Ck = 'CK',
  Cl = 'CL',
  Cm = 'CM',
  Cn = 'CN',
  Co = 'CO',
  Cr = 'CR',
  Cu = 'CU',
  Cv = 'CV',
  Cw = 'CW',
  Cx = 'CX',
  Cy = 'CY',
  Cz = 'CZ',
  De = 'DE',
  Dj = 'DJ',
  Dk = 'DK',
  Dm = 'DM',
  Do = 'DO',
  Dz = 'DZ',
  Ec = 'EC',
  Ee = 'EE',
  Eg = 'EG',
  Eh = 'EH',
  Er = 'ER',
  Es = 'ES',
  Et = 'ET',
  Fi = 'FI',
  Fj = 'FJ',
  Fk = 'FK',
  Fm = 'FM',
  Fo = 'FO',
  Fr = 'FR',
  Ga = 'GA',
  Gb = 'GB',
  Gd = 'GD',
  Ge = 'GE',
  Gf = 'GF',
  Gg = 'GG',
  Gh = 'GH',
  Gi = 'GI',
  Gl = 'GL',
  Gm = 'GM',
  Gn = 'GN',
  Gp = 'GP',
  Gq = 'GQ',
  Gr = 'GR',
  Gs = 'GS',
  Gt = 'GT',
  Gu = 'GU',
  Gw = 'GW',
  Gy = 'GY',
  Hk = 'HK',
  Hm = 'HM',
  Hn = 'HN',
  Hr = 'HR',
  Ht = 'HT',
  Hu = 'HU',
  Id = 'ID',
  Ie = 'IE',
  Il = 'IL',
  Im = 'IM',
  In = 'IN',
  Io = 'IO',
  Iq = 'IQ',
  Ir = 'IR',
  Is = 'IS',
  It = 'IT',
  Je = 'JE',
  Jm = 'JM',
  Jo = 'JO',
  Jp = 'JP',
  Ke = 'KE',
  Kg = 'KG',
  Kh = 'KH',
  Ki = 'KI',
  Km = 'KM',
  Kn = 'KN',
  Kp = 'KP',
  Kr = 'KR',
  Kw = 'KW',
  Ky = 'KY',
  Kz = 'KZ',
  La = 'LA',
  Lb = 'LB',
  Lc = 'LC',
  Li = 'LI',
  Lk = 'LK',
  Lr = 'LR',
  Ls = 'LS',
  Lt = 'LT',
  Lu = 'LU',
  Lv = 'LV',
  Ly = 'LY',
  Ma = 'MA',
  Mc = 'MC',
  Md = 'MD',
  Me = 'ME',
  Mf = 'MF',
  Mg = 'MG',
  Mh = 'MH',
  Mk = 'MK',
  Ml = 'ML',
  Mm = 'MM',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Mq = 'MQ',
  Mr = 'MR',
  Ms = 'MS',
  Mt = 'MT',
  Mu = 'MU',
  Mv = 'MV',
  Mw = 'MW',
  Mx = 'MX',
  My = 'MY',
  Mz = 'MZ',
  Na = 'NA',
  Nc = 'NC',
  Ne = 'NE',
  Nf = 'NF',
  Ng = 'NG',
  Ni = 'NI',
  Nl = 'NL',
  No = 'NO',
  Np = 'NP',
  Nr = 'NR',
  Nu = 'NU',
  Nz = 'NZ',
  Om = 'OM',
  Pa = 'PA',
  Pe = 'PE',
  Pf = 'PF',
  Pg = 'PG',
  Ph = 'PH',
  Pk = 'PK',
  Pl = 'PL',
  Pm = 'PM',
  Pn = 'PN',
  Pr = 'PR',
  Ps = 'PS',
  Pt = 'PT',
  Pw = 'PW',
  Py = 'PY',
  Qa = 'QA',
  Re = 'RE',
  Ro = 'RO',
  Rs = 'RS',
  Ru = 'RU',
  Rw = 'RW',
  Sa = 'SA',
  Sb = 'SB',
  Sc = 'SC',
  Sd = 'SD',
  Se = 'SE',
  Sg = 'SG',
  Sh = 'SH',
  Si = 'SI',
  Sj = 'SJ',
  Sk = 'SK',
  Sl = 'SL',
  Sm = 'SM',
  Sn = 'SN',
  So = 'SO',
  Sr = 'SR',
  Ss = 'SS',
  St = 'ST',
  Sv = 'SV',
  Sx = 'SX',
  Sy = 'SY',
  Sz = 'SZ',
  Tc = 'TC',
  Td = 'TD',
  Tf = 'TF',
  Tg = 'TG',
  Th = 'TH',
  Tj = 'TJ',
  Tk = 'TK',
  Tl = 'TL',
  Tm = 'TM',
  Tn = 'TN',
  To = 'TO',
  Tr = 'TR',
  Tt = 'TT',
  Tv = 'TV',
  Tw = 'TW',
  Tz = 'TZ',
  Ua = 'UA',
  Ug = 'UG',
  Um = 'UM',
  Us = 'US',
  Uy = 'UY',
  Uz = 'UZ',
  Va = 'VA',
  Vc = 'VC',
  Ve = 'VE',
  Vg = 'VG',
  Vi = 'VI',
  Vn = 'VN',
  Vu = 'VU',
  Wf = 'WF',
  Ws = 'WS',
  Ye = 'YE',
  Yt = 'YT',
  Za = 'ZA',
  Zm = 'ZM',
  Zw = 'ZW'
}

/** Autogenerated return type of Create. */
export type CreatePayload = {
  __typename?: 'CreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
  translationRequests?: Maybe<Array<TranslationRequest>>;
};

export type CreditCard = {
  __typename?: 'CreditCard';
  brand?: Maybe<Scalars['String']>;
  expMonth?: Maybe<Scalars['String']>;
  expYear?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
};

export enum CulturalEnum {
  GenderNeutral = 'gender_neutral',
  MaleFemale = 'male_female',
  NonBinary = 'non_binary'
}

export enum CurrencyEnum {
  Eur = 'EUR',
  Usd = 'USD'
}

/** Autogenerated input type of Destroy */
export type DestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of Destroy. */
export type DestroyPayload = {
  __typename?: 'DestroyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type Detail = {
  __typename?: 'Detail';
  cost?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  languageCode?: Maybe<Scalars['String']>;
  pricePerWord?: Maybe<Scalars['String']>;
  savings?: Maybe<Scalars['Float']>;
  words?: Maybe<Scalars['Int']>;
};

export type DeveloperLog = {
  __typename?: 'DeveloperLog';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  level: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  payload: Scalars['JSON'];
  type: Scalars['String'];
};

export type DeveloperLogPage = {
  __typename?: 'DeveloperLogPage';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  nodes: Array<DeveloperLog>;
  nodesCount: Scalars['Int'];
  pagesCount: Scalars['Int'];
};

/** Autogenerated input type of DeveloperLogsDownload */
export type DeveloperLogsDownloadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  fromDate?: InputMaybe<Scalars['ISO8601DateTime']>;
  level?: InputMaybe<DeveloperLogsLevelEnum>;
  toDate?: InputMaybe<Scalars['ISO8601DateTime']>;
  type?: InputMaybe<DeveloperLogsTypeEnum>;
};

/** Autogenerated return type of DeveloperLogsDownload. */
export type DeveloperLogsDownloadPayload = {
  __typename?: 'DeveloperLogsDownloadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  downloadId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export enum DeveloperLogsLevelEnum {
  Any = 'any',
  Error = 'error',
  Info = 'info',
  Warning = 'warning'
}

export enum DeveloperLogsTypeEnum {
  Any = 'any',
  Callback = 'callback',
  CallbackFailed = 'callback_failed',
  RequestFailed = 'request_failed',
  RequestOrdered = 'request_ordered',
  RequestQuoted = 'request_quoted',
  RequestSuccess = 'request_success'
}

export type EmailNotificationPreferences = {
  __typename?: 'EmailNotificationPreferences';
  dailyClientNotification: Scalars['Boolean'];
  reviewStyleGuide: Scalars['Boolean'];
  translatorComments: Scalars['Boolean'];
};

export type EmailNotificationPreferencesInput = {
  dailyClientNotification: Scalars['Boolean'];
  reviewStyleGuide: Scalars['Boolean'];
  translatorComments: Scalars['Boolean'];
};

export enum ErrorCodeEnum {
  /** Invalid Hubspot page type */
  InvalidPage = 'INVALID_PAGE',
  /** Too many Hubspot page variants */
  PageLimitReached = 'PAGE_LIMIT_REACHED',
  /** User must provide a valid payment method */
  PaymentRequired = 'PAYMENT_REQUIRED'
}

export type Feature = {
  __typename?: 'Feature';
  enabled: Scalars['Boolean'];
  feature: Scalars['String'];
};

/** Autogenerated input type of FileDownload */
export type FileDownloadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  format: TranslationRequestDownloadFormatEnum;
  translationRequestVersion: Scalars['ID'];
};

/** Autogenerated return type of FileDownload. */
export type FileDownloadPayload = {
  __typename?: 'FileDownloadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  downloadId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type FileOrderDetails = {
  __typename?: 'FileOrderDetails';
  orderDetails?: Maybe<TranslationRequestOrderDetails>;
  wordCount?: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of FileOrderDetails. */
export type FileOrderDetailsPayload = {
  __typename?: 'FileOrderDetailsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  guid?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export enum FileTypeEnum {
  TranslationMemoryFile = 'TranslationMemoryFile',
  TranslationRequestFile = 'TranslationRequestFile'
}

/** Autogenerated input type of FileUpload */
export type FileUploadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  csvDelimiter?: InputMaybe<Scalars['String']>;
  files: Array<Scalars['Upload']>;
  metadata?: InputMaybe<Scalars['JSON']>;
};

/** Autogenerated return type of FileUpload. */
export type FileUploadPayload = {
  __typename?: 'FileUploadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  files?: Maybe<Array<UploadedFile>>;
  success: Scalars['Boolean'];
};

export enum FormalityEnum {
  Formal = 'formal',
  Informal = 'informal'
}

/** Autogenerated input type of GenerateAccessKeys */
export type GenerateAccessKeysInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of GenerateAccessKeys. */
export type GenerateAccessKeysPayload = {
  __typename?: 'GenerateAccessKeysPayload';
  accessKey: Scalars['String'];
  accessSecret: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type GeneratedContent = {
  __typename?: 'GeneratedContent';
  additionalInstructions?: Maybe<Scalars['String']>;
  contentFormat: GeneratedContentFormatEnum;
  contentLength?: Maybe<Scalars['Int']>;
  contentType: GeneratedContentTypeEnum;
  createdAt: Scalars['ISO8601DateTime'];
  generations?: Maybe<Array<GeneratedContentGeneration>>;
  id: Scalars['ID'];
  languages?: Maybe<Array<Scalars['String']>>;
  status: GeneratedContentStatusEnum;
  styleGuide?: Maybe<GeneratedContentStyleGuide>;
  title?: Maybe<Scalars['String']>;
  tokens?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  uploadedFiles?: Maybe<Array<UploadedFile>>;
  words?: Maybe<Scalars['Int']>;
};

/** Autogenerated input type of GeneratedContentCreate */
export type GeneratedContentCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  generatedContent: GeneratedContentInput;
};

/** Autogenerated return type of GeneratedContentCreate. */
export type GeneratedContentCreatePayload = {
  __typename?: 'GeneratedContentCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  generatedContent?: Maybe<GeneratedContent>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of GeneratedContentCustomPrompt */
export type GeneratedContentCustomPromptInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  content: Scalars['String'];
  generatedContentGenerationId: Scalars['ID'];
  prompt: Scalars['String'];
};

/** Autogenerated return type of GeneratedContentCustomPrompt. */
export type GeneratedContentCustomPromptPayload = {
  __typename?: 'GeneratedContentCustomPromptPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
  text?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of GeneratedContentDestroy */
export type GeneratedContentDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  generatedContentId: Scalars['ID'];
};

/** Autogenerated return type of GeneratedContentDestroy. */
export type GeneratedContentDestroyPayload = {
  __typename?: 'GeneratedContentDestroyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export enum GeneratedContentFormatEnum {
  Articles = 'articles',
  BlogPosts = 'blog_posts',
  ConceptsForWeb = 'concepts_for_web',
  ConvertingWebinars = 'converting_webinars',
  IdeasForInfographics = 'ideas_for_infographics',
  Newsletters = 'newsletters',
  QuestionsForSurveys = 'questions_for_surveys',
  ScriptsForExplainerVideos = 'scripts_for_explainer_videos',
  ScriptsForPodcasts = 'scripts_for_podcasts',
  ScriptsForTutorials = 'scripts_for_tutorials',
  ScriptsForVideos = 'scripts_for_videos',
  ScriptsForVoiceovers = 'scripts_for_voiceovers',
  ScriptsForWebinars = 'scripts_for_webinars',
  SocialMediaPosts = 'social_media_posts',
  SummarizingArticles = 'summarizing_articles',
  TextForPitchDecks = 'text_for_pitch_decks',
  TextForSalesDecks = 'text_for_sales_decks',
  TextForSlidePresentations = 'text_for_slide_presentations',
  TransformingBlogPosts = 'transforming_blog_posts',
  WebsiteCopy = 'website_copy'
}

export type GeneratedContentGeneration = {
  __typename?: 'GeneratedContentGeneration';
  content?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  targetLanguage?: Maybe<Scalars['String']>;
  tokens?: Maybe<Scalars['Int']>;
  words?: Maybe<Scalars['Int']>;
};

/** Autogenerated input type of GeneratedContentGenerationUpdate */
export type GeneratedContentGenerationUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  content: Scalars['String'];
  generatedContentGenerationId: Scalars['ID'];
};

/** Autogenerated return type of GeneratedContentGenerationUpdate. */
export type GeneratedContentGenerationUpdatePayload = {
  __typename?: 'GeneratedContentGenerationUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  generatedContent?: Maybe<GeneratedContent>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of GeneratedContentImproveWriting */
export type GeneratedContentImproveWritingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  content: Scalars['String'];
  generatedContentGenerationId: Scalars['ID'];
};

/** Autogenerated return type of GeneratedContentImproveWriting. */
export type GeneratedContentImproveWritingPayload = {
  __typename?: 'GeneratedContentImproveWritingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
  text?: Maybe<Scalars['String']>;
};

export type GeneratedContentInput = {
  additionalInstructions?: InputMaybe<Scalars['String']>;
  contentFormat: GeneratedContentFormatEnum;
  contentLength?: InputMaybe<Scalars['Int']>;
  contentType: GeneratedContentTypeEnum;
  contextIds?: InputMaybe<Array<Scalars['ID']>>;
  languages: Array<Scalars['String']>;
  styleGuide?: InputMaybe<GeneratedContentStyleGuideInput>;
  title?: InputMaybe<Scalars['String']>;
  topic: Scalars['String'];
};

/** Autogenerated input type of GeneratedContentMakeLonger */
export type GeneratedContentMakeLongerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  content: Scalars['String'];
  generatedContentGenerationId: Scalars['ID'];
};

/** Autogenerated return type of GeneratedContentMakeLonger. */
export type GeneratedContentMakeLongerPayload = {
  __typename?: 'GeneratedContentMakeLongerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
  text?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of GeneratedContentMakeShorter */
export type GeneratedContentMakeShorterInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  content: Scalars['String'];
  generatedContentGenerationId: Scalars['ID'];
};

/** Autogenerated return type of GeneratedContentMakeShorter. */
export type GeneratedContentMakeShorterPayload = {
  __typename?: 'GeneratedContentMakeShorterPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
  text?: Maybe<Scalars['String']>;
};

export type GeneratedContentPage = {
  __typename?: 'GeneratedContentPage';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  nodes: Array<GeneratedContent>;
  nodesCount: Scalars['Int'];
  pagesCount: Scalars['Int'];
};

export enum GeneratedContentSortAttributeEnum {
  ContentFormat = 'content_format',
  ContentLength = 'content_length',
  ContentType = 'content_type',
  CreatedAt = 'created_at',
  Languages = 'languages',
  Status = 'status',
  Title = 'title',
  Words = 'words'
}

export enum GeneratedContentStatusEnum {
  Error = 'error',
  Pending = 'pending',
  Ready = 'ready'
}

export type GeneratedContentStyleGuide = {
  __typename?: 'GeneratedContentStyleGuide';
  culturalConsideration?: Maybe<CulturalEnum>;
  formality?: Maybe<FormalityEnum>;
  purposeOfText?: Maybe<PurposeEnum>;
  style?: Maybe<StyleEnum>;
  targetAudience?: Maybe<AudienceEnum>;
  toneOfVoice?: Maybe<ToneEnum>;
};

export type GeneratedContentStyleGuideInput = {
  culturalConsideration?: InputMaybe<CulturalEnum>;
  formality?: InputMaybe<FormalityEnum>;
  purposeOfText?: InputMaybe<PurposeEnum>;
  style?: InputMaybe<StyleEnum>;
  targetAudience?: InputMaybe<AudienceEnum>;
  toneOfVoice?: InputMaybe<ToneEnum>;
};

export enum GeneratedContentTypeEnum {
  AudioContent = 'audio_content',
  ContentRepurposing = 'content_repurposing',
  InteractiveContent = 'interactive_content',
  Presentations = 'presentations',
  WrittenContent = 'written_content'
}

/** Autogenerated input type of GeneratedContentUpdate */
export type GeneratedContentUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  generatedContent: GeneratedContentInput;
  generatedContentId: Scalars['ID'];
};

/** Autogenerated return type of GeneratedContentUpdate. */
export type GeneratedContentUpdatePayload = {
  __typename?: 'GeneratedContentUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  generatedContent?: Maybe<GeneratedContent>;
  success: Scalars['Boolean'];
};

export type GithubRepo = {
  __typename?: 'GithubRepo';
  id: Scalars['ID'];
  personalToken?: Maybe<Scalars['String']>;
  pullRequestsSummary?: Maybe<Scalars['JSON']>;
  repoName: Scalars['String'];
  status?: Maybe<GithubStatusEnum>;
};

/** Autogenerated input type of GithubRepoCreate */
export type GithubRepoCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  personalToken?: InputMaybe<Scalars['String']>;
  repoName: Scalars['String'];
  useSameToken: Scalars['Boolean'];
};

/** Autogenerated return type of GithubRepoCreate. */
export type GithubRepoCreatePayload = {
  __typename?: 'GithubRepoCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of GithubRepoDestroy */
export type GithubRepoDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  repoId: Scalars['ID'];
};

/** Autogenerated return type of GithubRepoDestroy. */
export type GithubRepoDestroyPayload = {
  __typename?: 'GithubRepoDestroyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of GithubRepoUpdate */
export type GithubRepoUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  personalToken?: InputMaybe<Scalars['String']>;
  repoId: Scalars['ID'];
  repoName: Scalars['String'];
};

/** Autogenerated return type of GithubRepoUpdate. */
export type GithubRepoUpdatePayload = {
  __typename?: 'GithubRepoUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export enum GithubStatusEnum {
  Default = 'DEFAULT',
  Delete = 'DELETE',
  Edit = 'EDIT'
}

export type Glossary = {
  __typename?: 'Glossary';
  createdAt: Scalars['ISO8601DateTime'];
  default: Scalars['Boolean'];
  defaultLanguage?: Maybe<Scalars['String']>;
  glossaryTerms?: Maybe<GlossaryTermPage>;
  id: Scalars['ID'];
  languageCodes: Array<Scalars['String']>;
  name: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type GlossaryGlossaryTermsArgs = {
  ownedByClient?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  skipPagination?: InputMaybe<Scalars['Boolean']>;
  suggestions?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated input type of GlossaryCreate */
export type GlossaryCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  glossaryAttributes: GlossaryInput;
  glossaryTerms: Array<Scalars['JSON']>;
};

/** Autogenerated return type of GlossaryCreate. */
export type GlossaryCreatePayload = {
  __typename?: 'GlossaryCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  glossary?: Maybe<Glossary>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of GlossaryDestroy */
export type GlossaryDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  glossaryId: Scalars['ID'];
};

/** Autogenerated return type of GlossaryDestroy. */
export type GlossaryDestroyPayload = {
  __typename?: 'GlossaryDestroyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  glossary?: Maybe<Glossary>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of GlossaryDownload */
export type GlossaryDownloadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  glossaryId: Scalars['ID'];
};

/** Autogenerated return type of GlossaryDownload. */
export type GlossaryDownloadPayload = {
  __typename?: 'GlossaryDownloadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  downloadId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type GlossaryInput = {
  default: Scalars['Boolean'];
  languageCodes: Array<Scalars['String']>;
  name: Scalars['String'];
};

export type GlossaryItem = {
  __typename?: 'GlossaryItem';
  languageCode: Scalars['String'];
  term: Scalars['String'];
};

export type GlossaryPage = {
  __typename?: 'GlossaryPage';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  nodes: Array<Glossary>;
  nodesCount: Scalars['Int'];
  pagesCount: Scalars['Int'];
};

export type GlossarySearch = {
  __typename?: 'GlossarySearch';
  highlights?: Maybe<Scalars['JSON']>;
  pages: Scalars['Int'];
  terms: Array<Scalars['JSON']>;
};

export type GlossaryTerm = {
  __typename?: 'GlossaryTerm';
  glossaryItems?: Maybe<Array<GlossaryItem>>;
  id: Scalars['ID'];
  ownedByClient?: Maybe<Scalars['Boolean']>;
};

export type GlossaryTermPage = {
  __typename?: 'GlossaryTermPage';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  nodes: Array<GlossaryTerm>;
  nodesCount: Scalars['Int'];
  pagesCount: Scalars['Int'];
};

/** Autogenerated input type of GlossaryUpdate */
export type GlossaryUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  glossaryAttributes: GlossaryInput;
  glossaryId: Scalars['ID'];
};

/** Autogenerated return type of GlossaryUpdate. */
export type GlossaryUpdatePayload = {
  __typename?: 'GlossaryUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  glossary?: Maybe<Glossary>;
  success: Scalars['Boolean'];
};

export type GoogleIntegration = {
  __typename?: 'GoogleIntegration';
  id: Scalars['ID'];
};

/** Autogenerated input type of GoogleIntegrationCreate */
export type GoogleIntegrationCreateInput = {
  authorizationToken: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of GoogleIntegrationCreate. */
export type GoogleIntegrationCreatePayload = {
  __typename?: 'GoogleIntegrationCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  googleIntegration?: Maybe<GoogleIntegration>;
  success: Scalars['Boolean'];
  token?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type HubspotIntegration = {
  __typename?: 'HubspotIntegration';
  applySelection?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  selectedKeys?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated input type of HubspotIntegrationCreate */
export type HubspotIntegrationCreateInput = {
  applySelection?: InputMaybe<Scalars['Boolean']>;
  authorizationToken: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  selectedKeys?: InputMaybe<Array<Scalars['String']>>;
};

/** Autogenerated return type of HubspotIntegrationCreate. */
export type HubspotIntegrationCreatePayload = {
  __typename?: 'HubspotIntegrationCreatePayload';
  clientInvitation?: Maybe<ClientInvite>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  hubspotIntegration?: Maybe<HubspotIntegration>;
  success: Scalars['Boolean'];
  token?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

/** Autogenerated input type of HubspotIntegrationDestroy */
export type HubspotIntegrationDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of HubspotIntegrationDestroy. */
export type HubspotIntegrationDestroyPayload = {
  __typename?: 'HubspotIntegrationDestroyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of HubspotIntegrationPush */
export type HubspotIntegrationPushInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  translationRequestVersion?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of HubspotIntegrationPush. */
export type HubspotIntegrationPushPayload = {
  __typename?: 'HubspotIntegrationPushPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of HubspotIntegrationUpdate */
export type HubspotIntegrationUpdateInput = {
  applySelection?: InputMaybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  selectedKeys?: InputMaybe<Array<Scalars['String']>>;
};

/** Autogenerated return type of HubspotIntegrationUpdate. */
export type HubspotIntegrationUpdatePayload = {
  __typename?: 'HubspotIntegrationUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  hubspotIntegration?: Maybe<HubspotIntegration>;
  success: Scalars['Boolean'];
};

export type HubspotPage = {
  __typename?: 'HubspotPage';
  jsonContent?: Maybe<Scalars['JSON']>;
  pageId: Scalars['ID'];
  pageType?: Maybe<HubspotPageTypeEnum>;
  status?: Maybe<TranslationRequestStatusEnum>;
};

export type HubspotPagePage = {
  __typename?: 'HubspotPagePage';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  nodes: Array<HubspotPage>;
  nodesCount: Scalars['Int'];
  pagesCount: Scalars['Int'];
};

export enum HubspotPageSortAttributeEnum {
  Language = 'language',
  Name = 'name',
  PublishDate = 'publish_date'
}

export enum HubspotPageTypeEnum {
  BlogPages = 'blog_pages',
  LandingPages = 'landing_pages',
  SitePages = 'site_pages'
}

export type HubspotTranslation = {
  __typename?: 'HubspotTranslation';
  filename: Scalars['String'];
  hubspotSiteId: Scalars['ID'];
  jsonTranslation: Scalars['JSON'];
  type: Scalars['String'];
};

export enum IntegrationEnum {
  ChatAi = 'chat_ai',
  ContentCreator = 'content_creator',
  Github = 'github',
  Hubspot = 'hubspot'
}

export type Invitation = {
  __typename?: 'Invitation';
  confirmedAt?: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Scalars['ISO8601DateTime'];
  currency: Scalars['String'];
  earningsPerWord?: Maybe<Scalars['Float']>;
  earningsPerWordUsd?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  proofreading: Scalars['Boolean'];
  reminderSent: Scalars['Boolean'];
  skill: Skill;
  task: Task;
  transactionsCreatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  translatorAccount?: Maybe<TranslatorAccount>;
  updatedAt: Scalars['ISO8601DateTime'];
  workCompletedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of Invite */
export type InviteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
};

/** Autogenerated return type of Invite. */
export type InvitePayload = {
  __typename?: 'InvitePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
  user?: Maybe<User>;
};

export type Invoice = {
  __typename?: 'Invoice';
  amount: Scalars['Float'];
  chamberOfCommerce?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  currency: CurrencyEnum;
  customerNumber: Scalars['Int'];
  dueDate?: Maybe<Scalars['ISO8601DateTime']>;
  fromAddress: Scalars['String'];
  id: Scalars['ID'];
  invoiceItems: Array<InvoiceItem>;
  issuedAt?: Maybe<Scalars['ISO8601Date']>;
  legacy: Scalars['Boolean'];
  netAmount: Scalars['Float'];
  overdue: Scalars['Boolean'];
  paid: Scalars['Boolean'];
  paymentMethod: PaymentMethodEnum;
  poCode?: Maybe<Scalars['String']>;
  taxAmount: Scalars['Float'];
  taxId?: Maybe<Scalars['String']>;
  toAddress?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of InvoiceDownload */
export type InvoiceDownloadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  invoiceId: Scalars['ID'];
};

/** Autogenerated return type of InvoiceDownload. */
export type InvoiceDownloadPayload = {
  __typename?: 'InvoiceDownloadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  downloadId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type InvoiceItem = {
  __typename?: 'InvoiceItem';
  amount: Scalars['Float'];
  createdAt: Scalars['ISO8601DateTime'];
  currency: Scalars['String'];
  netAmount: Scalars['Float'];
  orderCode?: Maybe<Scalars['String']>;
  subItems?: Maybe<Array<InvoiceSubItem>>;
  taxAmount: Scalars['Float'];
  taxCode?: Maybe<Scalars['String']>;
  taxRate: Scalars['Float'];
  title: Scalars['String'];
};

export type InvoicePage = {
  __typename?: 'InvoicePage';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  nodes: Array<Invoice>;
  nodesCount: Scalars['Int'];
  pagesCount: Scalars['Int'];
};

export enum InvoiceSortAttributeEnum {
  Amount = 'amount',
  ChamberOfCommerce = 'chamber_of_commerce',
  CreatedAt = 'created_at',
  Currency = 'currency',
  DueDate = 'due_date',
  FromAddress = 'from_address',
  Id = 'id',
  NetAmount = 'net_amount',
  Paid = 'paid',
  PoCode = 'po_code',
  TaxAmount = 'tax_amount',
  TaxId = 'tax_id',
  ToAddress = 'to_address'
}

export type InvoiceSubItem = {
  __typename?: 'InvoiceSubItem';
  amount?: Maybe<Scalars['Float']>;
  netAmount?: Maybe<Scalars['Float']>;
  sourceLanguageCode?: Maybe<Scalars['String']>;
  targetLanguageCode?: Maybe<Scalars['String']>;
  taxAmount?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of InvoiceUpdate */
export type InvoiceUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  invoiceId: Scalars['ID'];
  invoiceInput: InvoiceUpdateInvoiceInput;
};

export type InvoiceUpdateInvoiceInput = {
  poCode?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of InvoiceUpdate. */
export type InvoiceUpdatePayload = {
  __typename?: 'InvoiceUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  invoice?: Maybe<Invoice>;
  success: Scalars['Boolean'];
};

export type KeyData = {
  __typename?: 'KeyData';
  charLimit?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  nonInteractive?: Maybe<Scalars['Boolean']>;
  sourceChunkIds?: Maybe<Array<Scalars['Int']>>;
  translationRequestId?: Maybe<Scalars['Int']>;
};

export type Language = {
  __typename?: 'Language';
  code?: Maybe<Scalars['String']>;
  dialect?: Maybe<Scalars['Boolean']>;
  direction?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nativename?: Maybe<Scalars['String']>;
  orderable?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated input type of LanguageAdd */
export type LanguageAddInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  glossaryId: Scalars['ID'];
  languageCode: Scalars['String'];
};

/** Autogenerated return type of LanguageAdd. */
export type LanguageAddPayload = {
  __typename?: 'LanguageAddPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  glossary?: Maybe<Glossary>;
  success: Scalars['Boolean'];
};

export type LanguageData = {
  __typename?: 'LanguageData';
  dialectData?: Maybe<Scalars['JSON']>;
};

/** Autogenerated input type of LanguageDestroy */
export type LanguageDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  glossaryId: Scalars['ID'];
  languageCode: Scalars['String'];
};

/** Autogenerated return type of LanguageDestroy. */
export type LanguageDestroyPayload = {
  __typename?: 'LanguageDestroyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  glossary?: Maybe<Glossary>;
  success: Scalars['Boolean'];
};

export type LanguagePair = {
  __typename?: 'LanguagePair';
  description: Scalars['String'];
  sourceLanguageCode: Scalars['String'];
  targetLanguageCode: Scalars['String'];
};

/** Autogenerated input type of LanguageUpdateDefault */
export type LanguageUpdateDefaultInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  defaultLanguageCode: OrderableTargetLanguageCodesEnum;
  glossaryId: Scalars['ID'];
};

/** Autogenerated return type of LanguageUpdateDefault. */
export type LanguageUpdateDefaultPayload = {
  __typename?: 'LanguageUpdateDefaultPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  glossary?: Maybe<Glossary>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of LocalSignin */
export type LocalSigninInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
};

/** Autogenerated return type of LocalSignin. */
export type LocalSigninPayload = {
  __typename?: 'LocalSigninPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
  userLocalSession?: Maybe<UserLocalSession>;
};

export type MachineTranslator = TranslatorInterface & {
  __typename?: 'MachineTranslator';
  createdAt: Scalars['ISO8601DateTime'];
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>;
  engine?: Maybe<NmtEngineEnum>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: TranslatorTypeEnum;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type Member = {
  __typename?: 'Member';
  acceptedAt?: Maybe<Scalars['ISO8601DateTime']>;
  email: Scalars['String'];
  id: Scalars['ID'];
  lastSignInAt?: Maybe<Scalars['ISO8601DateTime']>;
  name?: Maybe<Scalars['String']>;
  role: RoleEnum;
  translationRequestCount?: Maybe<Scalars['Int']>;
};

/** Autogenerated input type of MemberInvitationConfirm */
export type MemberInvitationConfirmInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
};

/** Autogenerated return type of MemberInvitationConfirm. */
export type MemberInvitationConfirmPayload = {
  __typename?: 'MemberInvitationConfirmPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of MemberInvitationResend */
export type MemberInvitationResendInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  memberUserId: Scalars['ID'];
};

/** Autogenerated return type of MemberInvitationResend. */
export type MemberInvitationResendPayload = {
  __typename?: 'MemberInvitationResendPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type MemberInvite = {
  __typename?: 'MemberInvite';
  companyName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};

/** Autogenerated input type of MemberRemove */
export type MemberRemoveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  memberUserId: Scalars['ID'];
};

/** Autogenerated return type of MemberRemove. */
export type MemberRemovePayload = {
  __typename?: 'MemberRemovePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
  users?: Maybe<Array<User>>;
};

/** Autogenerated input type of MemberSetPassword */
export type MemberSetPasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  token: Scalars['String'];
};

/** Autogenerated return type of MemberSetPassword. */
export type MemberSetPasswordPayload = {
  __typename?: 'MemberSetPasswordPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type Message = {
  __typename?: 'Message';
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  role: UniversalChatRoleEnum;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type MessagePage = {
  __typename?: 'MessagePage';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  nodes: Array<Message>;
  nodesCount: Scalars['Int'];
  pagesCount: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  accountSetPassword?: Maybe<AccountSetPasswordPayload>;
  billingInfoUpdate?: Maybe<BillingInfoUpdatePayload>;
  clientAccountAddLanguage?: Maybe<AddLanguagePayload>;
  clientAccountChangeDefaultLanguage?: Maybe<ChangeDefaultLanguagePayload>;
  clientAccountDestroy?: Maybe<DestroyPayload>;
  clientAccountRemoveLanguage?: Maybe<RemoveLanguagePayload>;
  clientAccountUpdate?: Maybe<UpdatePayload>;
  clientAccountUpdateDialects?: Maybe<UpdateDialectsPayload>;
  commentCreate?: Maybe<CommentCreatePayload>;
  contextLibraryFileDestroy?: Maybe<ContextLibraryDestroyPayload>;
  contextLibraryFileUpdate?: Maybe<ContextLibraryUpdatePayload>;
  contextLibraryFileUpload?: Maybe<ContextLibraryFileUploadPayload>;
  developerLogsDownload?: Maybe<DeveloperLogsDownloadPayload>;
  generatedContentCreate?: Maybe<GeneratedContentCreatePayload>;
  generatedContentCustomPrompt?: Maybe<GeneratedContentCustomPromptPayload>;
  generatedContentDestroy?: Maybe<GeneratedContentDestroyPayload>;
  generatedContentGenerationUpdate?: Maybe<GeneratedContentGenerationUpdatePayload>;
  generatedContentImproveWriting?: Maybe<GeneratedContentImproveWritingPayload>;
  generatedContentMakeLonger?: Maybe<GeneratedContentMakeLongerPayload>;
  generatedContentMakeShorter?: Maybe<GeneratedContentMakeShorterPayload>;
  generatedContentUpdate?: Maybe<GeneratedContentUpdatePayload>;
  githubRepoCreate?: Maybe<GithubRepoCreatePayload>;
  githubRepoDestroy?: Maybe<GithubRepoDestroyPayload>;
  githubRepoUpdate?: Maybe<GithubRepoUpdatePayload>;
  glossaryCreate?: Maybe<GlossaryCreatePayload>;
  glossaryDestroy?: Maybe<GlossaryDestroyPayload>;
  glossaryDownload?: Maybe<GlossaryDownloadPayload>;
  glossaryLanguageAdd?: Maybe<LanguageAddPayload>;
  glossaryLanguageDestroy?: Maybe<LanguageDestroyPayload>;
  glossaryLanguageUpdateDefault?: Maybe<LanguageUpdateDefaultPayload>;
  glossaryTermAdd?: Maybe<TermAddPayload>;
  glossaryTermApproveAndAssign?: Maybe<ApproveAndAssignPayload>;
  glossaryTermDestroy?: Maybe<TermDestroyPayload>;
  glossaryTermInclude?: Maybe<TermIncludePayload>;
  glossaryTermUpdate?: Maybe<TermUpdatePayload>;
  glossaryUpdate?: Maybe<GlossaryUpdatePayload>;
  googleIntegrationCreate?: Maybe<GoogleIntegrationCreatePayload>;
  hubspotIntegrationCreate?: Maybe<HubspotIntegrationCreatePayload>;
  hubspotIntegrationDestroy?: Maybe<HubspotIntegrationDestroyPayload>;
  hubspotIntegrationPush?: Maybe<HubspotIntegrationPushPayload>;
  hubspotIntegrationUpdate?: Maybe<HubspotIntegrationUpdatePayload>;
  invoiceDownload?: Maybe<InvoiceDownloadPayload>;
  invoiceUpdate?: Maybe<InvoiceUpdatePayload>;
  memberInvitationConfirm?: Maybe<MemberInvitationConfirmPayload>;
  memberInvitationResend?: Maybe<MemberInvitationResendPayload>;
  memberInvite?: Maybe<InvitePayload>;
  memberRemove?: Maybe<MemberRemovePayload>;
  memberSetPassword?: Maybe<MemberSetPasswordPayload>;
  orderCancel?: Maybe<OrderCancelPayload>;
  paymentMethodAttach?: Maybe<PaymentMethodAttachPayload>;
  purchaseOrderCancel?: Maybe<PurchaseOrderCancelPayload>;
  purchaseOrderRetry?: Maybe<PurchaseOrderRetryPayload>;
  segmentsUpdate?: Maybe<SegmentsUpdatePayload>;
  setupIntentCreate?: Maybe<SetupIntentCreatePayload>;
  styleGuideApprove?: Maybe<StyleGuideApprovePayload>;
  styleGuideCreate?: Maybe<StyleGuideCreatePayload>;
  styleGuideDestroy?: Maybe<StyleGuideDestroyPayload>;
  styleGuideUpdate?: Maybe<StyleGuideUpdatePayload>;
  taskAccept?: Maybe<TaskAcceptPayload>;
  taskCommentsRead?: Maybe<CommentsReadPayload>;
  taskRejectPhrases?: Maybe<RejectPhrasesPayload>;
  transactionsDownload?: Maybe<TransactionsDownloadPayload>;
  translationMemoryDestroy?: Maybe<TranslationMemoryDestroyPayload>;
  translationMemoryUpdate?: Maybe<TranslationMemoryUpdatePayload>;
  translationMemoryUpload?: Maybe<UploadPayload>;
  translationRequestAcceptAll?: Maybe<TranslationRequestAcceptAllPayload>;
  translationRequestBulkDownload?: Maybe<TranslationRequestBulDownloadPayload>;
  translationRequestBulkOrder?: Maybe<TranslationRequestBulkOrderPayload>;
  translationRequestCallbackRetry?: Maybe<CallbackRetryPayload>;
  translationRequestColumnCharLimit?: Maybe<ColumnCharLimitPayload>;
  translationRequestCreate?: Maybe<CreatePayload>;
  translationRequestDestroy?: Maybe<TranslationRequestDestroyPayload>;
  translationRequestFileDownload?: Maybe<FileDownloadPayload>;
  translationRequestFileOrderDetails?: Maybe<FileOrderDetailsPayload>;
  translationRequestFileUpload?: Maybe<FileUploadPayload>;
  translationRequestOrder?: Maybe<TranslationRequestOrderPayload>;
  translationRequestOrderDetails?: Maybe<OrderDetailsPayload>;
  translationRequestReplaceQuote?: Maybe<ReplaceQuotePayload>;
  universalChatMessageCreate?: Maybe<UniversalChatMessageCreatePayload>;
  universalChatMessageDestroy?: Maybe<UniversalChatMessageDestroyPayload>;
  universalChatThreadDestroy?: Maybe<UniversalChatThreadDestroyPayload>;
  universalChatThreadUpdate?: Maybe<UniversalChatThreadUpdatePayload>;
  unsubscribe?: Maybe<UnsubscribePayload>;
  userBecome?: Maybe<BecomePayload>;
  userConfirmEmailChange?: Maybe<ConfirmEmailChangePayload>;
  userConfirmSignup?: Maybe<ConfirmSignupPayload>;
  userGenerateAccessKeys?: Maybe<GenerateAccessKeysPayload>;
  userSignup?: Maybe<SignupPayload>;
  userTwoFactor?: Maybe<TwoFactorPayload>;
};

export type MutationAccountSetPasswordArgs = {
  input: AccountSetPasswordInput;
};

export type MutationBillingInfoUpdateArgs = {
  input: BillingInfoInput;
};

export type MutationClientAccountAddLanguageArgs = {
  input: AddLanguageInput;
};

export type MutationClientAccountChangeDefaultLanguageArgs = {
  input: ChangeDefaultLanguageInput;
};

export type MutationClientAccountDestroyArgs = {
  input: DestroyInput;
};

export type MutationClientAccountRemoveLanguageArgs = {
  input: RemoveLanguageInput;
};

export type MutationClientAccountUpdateArgs = {
  input: ClientAccountUpdateInput;
};

export type MutationClientAccountUpdateDialectsArgs = {
  input: UpdateDialectsInput;
};

export type MutationCommentCreateArgs = {
  input: CommentCreateInput;
};

export type MutationContextLibraryFileDestroyArgs = {
  input: ContextLibraryDestroyInput;
};

export type MutationContextLibraryFileUpdateArgs = {
  input: ContextLibraryUpdateInput;
};

export type MutationContextLibraryFileUploadArgs = {
  input: ContextLibraryFileUploadInput;
};

export type MutationDeveloperLogsDownloadArgs = {
  input: DeveloperLogsDownloadInput;
};

export type MutationGeneratedContentCreateArgs = {
  input: GeneratedContentCreateInput;
};

export type MutationGeneratedContentCustomPromptArgs = {
  input: GeneratedContentCustomPromptInput;
};

export type MutationGeneratedContentDestroyArgs = {
  input: GeneratedContentDestroyInput;
};

export type MutationGeneratedContentGenerationUpdateArgs = {
  input: GeneratedContentGenerationUpdateInput;
};

export type MutationGeneratedContentImproveWritingArgs = {
  input: GeneratedContentImproveWritingInput;
};

export type MutationGeneratedContentMakeLongerArgs = {
  input: GeneratedContentMakeLongerInput;
};

export type MutationGeneratedContentMakeShorterArgs = {
  input: GeneratedContentMakeShorterInput;
};

export type MutationGeneratedContentUpdateArgs = {
  input: GeneratedContentUpdateInput;
};

export type MutationGithubRepoCreateArgs = {
  input: GithubRepoCreateInput;
};

export type MutationGithubRepoDestroyArgs = {
  input: GithubRepoDestroyInput;
};

export type MutationGithubRepoUpdateArgs = {
  input: GithubRepoUpdateInput;
};

export type MutationGlossaryCreateArgs = {
  input: GlossaryCreateInput;
};

export type MutationGlossaryDestroyArgs = {
  input: GlossaryDestroyInput;
};

export type MutationGlossaryDownloadArgs = {
  input: GlossaryDownloadInput;
};

export type MutationGlossaryLanguageAddArgs = {
  input: LanguageAddInput;
};

export type MutationGlossaryLanguageDestroyArgs = {
  input: LanguageDestroyInput;
};

export type MutationGlossaryLanguageUpdateDefaultArgs = {
  input: LanguageUpdateDefaultInput;
};

export type MutationGlossaryTermAddArgs = {
  input: TermAddInput;
};

export type MutationGlossaryTermApproveAndAssignArgs = {
  input: ApproveAndAssignInput;
};

export type MutationGlossaryTermDestroyArgs = {
  input: TermDestroyInput;
};

export type MutationGlossaryTermIncludeArgs = {
  input: TermIncludeInput;
};

export type MutationGlossaryTermUpdateArgs = {
  input: TermUpdateInput;
};

export type MutationGlossaryUpdateArgs = {
  input: GlossaryUpdateInput;
};

export type MutationGoogleIntegrationCreateArgs = {
  input: GoogleIntegrationCreateInput;
};

export type MutationHubspotIntegrationCreateArgs = {
  input: HubspotIntegrationCreateInput;
};

export type MutationHubspotIntegrationDestroyArgs = {
  input: HubspotIntegrationDestroyInput;
};

export type MutationHubspotIntegrationPushArgs = {
  input: HubspotIntegrationPushInput;
};

export type MutationHubspotIntegrationUpdateArgs = {
  input: HubspotIntegrationUpdateInput;
};

export type MutationInvoiceDownloadArgs = {
  input: InvoiceDownloadInput;
};

export type MutationInvoiceUpdateArgs = {
  input: InvoiceUpdateInput;
};

export type MutationMemberInvitationConfirmArgs = {
  input: MemberInvitationConfirmInput;
};

export type MutationMemberInvitationResendArgs = {
  input: MemberInvitationResendInput;
};

export type MutationMemberInviteArgs = {
  input: InviteInput;
};

export type MutationMemberRemoveArgs = {
  input: MemberRemoveInput;
};

export type MutationMemberSetPasswordArgs = {
  input: MemberSetPasswordInput;
};

export type MutationOrderCancelArgs = {
  input: OrderCancelInput;
};

export type MutationPaymentMethodAttachArgs = {
  input: PaymentMethodAttachInput;
};

export type MutationPurchaseOrderCancelArgs = {
  input: PurchaseOrderCancelInput;
};

export type MutationPurchaseOrderRetryArgs = {
  input: PurchaseOrderRetryInput;
};

export type MutationSegmentsUpdateArgs = {
  input: SegmentsUpdateInput;
};

export type MutationSetupIntentCreateArgs = {
  input: SetupIntentCreateInput;
};

export type MutationStyleGuideApproveArgs = {
  input: StyleGuideApproveInput;
};

export type MutationStyleGuideCreateArgs = {
  input: StyleGuideCreateInput;
};

export type MutationStyleGuideDestroyArgs = {
  input: StyleGuideDestroyInput;
};

export type MutationStyleGuideUpdateArgs = {
  input: StyleGuideUpdateInput;
};

export type MutationTaskAcceptArgs = {
  input: TaskAcceptInput;
};

export type MutationTaskCommentsReadArgs = {
  input: CommentsReadInput;
};

export type MutationTaskRejectPhrasesArgs = {
  input: RejectPhrasesInput;
};

export type MutationTransactionsDownloadArgs = {
  input: TransactionsDownloadInput;
};

export type MutationTranslationMemoryDestroyArgs = {
  input: TranslationMemoryDestroyInput;
};

export type MutationTranslationMemoryUpdateArgs = {
  input: TranslationMemoryUpdateInput;
};

export type MutationTranslationMemoryUploadArgs = {
  input: UploadInput;
};

export type MutationTranslationRequestAcceptAllArgs = {
  input: TranslationRequestAcceptAllInput;
};

export type MutationTranslationRequestBulkDownloadArgs = {
  input: TranslationRequestBulDownloadInput;
};

export type MutationTranslationRequestBulkOrderArgs = {
  input: TranslationRequestBulkOrderInput;
};

export type MutationTranslationRequestCallbackRetryArgs = {
  input: CallbackRetryInput;
};

export type MutationTranslationRequestColumnCharLimitArgs = {
  input: ColumnCharLimitInput;
};

export type MutationTranslationRequestCreateArgs = {
  input: TranslationRequestInput;
};

export type MutationTranslationRequestDestroyArgs = {
  input: TranslationRequestDestroyInput;
};

export type MutationTranslationRequestFileDownloadArgs = {
  input: FileDownloadInput;
};

export type MutationTranslationRequestFileOrderDetailsArgs = {
  input: TranslationRequestInput;
};

export type MutationTranslationRequestFileUploadArgs = {
  input: FileUploadInput;
};

export type MutationTranslationRequestOrderArgs = {
  input: TranslationRequestOrderInput;
};

export type MutationTranslationRequestOrderDetailsArgs = {
  input: TranslationRequestInput;
};

export type MutationTranslationRequestReplaceQuoteArgs = {
  input: ReplaceQuoteInput;
};

export type MutationUniversalChatMessageCreateArgs = {
  input: UniversalChatMessageCreateInput;
};

export type MutationUniversalChatMessageDestroyArgs = {
  input: UniversalChatMessageDestroyInput;
};

export type MutationUniversalChatThreadDestroyArgs = {
  input: UniversalChatThreadDestroyInput;
};

export type MutationUniversalChatThreadUpdateArgs = {
  input: UniversalChatThreadUpdateInput;
};

export type MutationUnsubscribeArgs = {
  input: UnsubscribeInput;
};

export type MutationUserBecomeArgs = {
  input: BecomeInput;
};

export type MutationUserConfirmEmailChangeArgs = {
  input: ConfirmEmailChangeInput;
};

export type MutationUserConfirmSignupArgs = {
  input: ConfirmSignupInput;
};

export type MutationUserGenerateAccessKeysArgs = {
  input: GenerateAccessKeysInput;
};

export type MutationUserLocalSigninArgs = {
  input: LocalSigninInput;
};

export type MutationUserSignupArgs = {
  input: SignupInput;
};

export type MutationUserTwoFactorArgs = {
  input: TwoFactorInput;
};

export enum NmtEngineEnum {
  Amazon = 'amazon',
  Anthropic = 'anthropic',
  ChatGpt = 'chat_gpt',
  ChatGpt4 = 'chat_gpt4',
  ChatGpt4_2024 = 'chat_gpt4_2024',
  ChatGpt4o_2024 = 'chat_gpt4o_2024',
  Davinci_003 = 'davinci_003',
  DeepL = 'deep_l',
  Google = 'google',
  Microsoft = 'microsoft',
  OpenAi = 'open_ai',
  Tolq_3b = 'tolq_3b'
}

export type Order = {
  __typename?: 'Order';
  acceptedAt?: Maybe<Scalars['ISO8601DateTime']>;
  actualUsdCost?: Maybe<Scalars['Float']>;
  cancelable: Scalars['Boolean'];
  clientAccount: ClientAccount;
  createdAt: Scalars['ISO8601DateTime'];
  creditedAt?: Maybe<Scalars['ISO8601DateTime']>;
  currency: CurrencyEnum;
  id: Scalars['ID'];
  orderCompletedAt?: Maybe<Scalars['ISO8601DateTime']>;
  paidAt?: Maybe<Scalars['ISO8601DateTime']>;
  payableAmount: Scalars['Float'];
  price: Scalars['Float'];
  pricePerWord?: Maybe<Scalars['Float']>;
  purchaseOrder: PurchaseOrder;
  quality: QualityEnum;
  targetLanguageCode?: Maybe<Scalars['String']>;
  taxAmount: Scalars['Float'];
  totalOrderedSentenceCount?: Maybe<Scalars['Int']>;
  totalOrderedWordCount?: Maybe<Scalars['Int']>;
  translationRequest?: Maybe<TranslationRequest>;
  updatedAt: Scalars['ISO8601DateTime'];
  workflowState: OrderWorkflowStateEnum;
};

/** Autogenerated input type of OrderCancel */
export type OrderCancelInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  translationRequestVersion: Scalars['ID'];
};

/** Autogenerated return type of OrderCancel. */
export type OrderCancelPayload = {
  __typename?: 'OrderCancelPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type OrderDetails = {
  __typename?: 'OrderDetails';
  createdAt: Scalars['ISO8601DateTime'];
  duplicateOfVersion?: Maybe<Scalars['Int']>;
  initialCallbackResponse?: Maybe<Scalars['String']>;
  initialCallbackStatus: Scalars['String'];
  paidAt: Scalars['ISO8601DateTime'];
  paymentFailed: Scalars['Boolean'];
  pricingData?: Maybe<PricingData>;
  purchaseOrderCode?: Maybe<Scalars['String']>;
  sentences: Sentences;
  shouldDoCallback: Scalars['Boolean'];
  shouldShowTasksInProgress: Scalars['Boolean'];
  styleGuideId?: Maybe<Scalars['Int']>;
  tasksInProgress: TaskPage;
  waitingInReviewSince?: Maybe<Scalars['ISO8601DateTime']>;
};

export type OrderDetailsTasksInProgressArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  skipPagination?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated return type of OrderDetails. */
export type OrderDetailsPayload = {
  __typename?: 'OrderDetailsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  orderDetails?: Maybe<TranslationRequestOrderDetails>;
  success: Scalars['Boolean'];
  wordCount?: Maybe<Scalars['Int']>;
};

export enum OrderWorkflowStateEnum {
  Canceled = 'canceled',
  Closed = 'closed',
  Finished = 'finished',
  Invoiced = 'invoiced',
  New = 'new',
  PaymentFailed = 'payment_failed'
}

export enum OrderableTargetLanguageCodesEnum {
  Af = 'af',
  Ar = 'ar',
  Bg = 'bg',
  Bs = 'bs',
  Ca = 'ca',
  Cs = 'cs',
  Da = 'da',
  De = 'de',
  El = 'el',
  En = 'en',
  Es = 'es',
  Et = 'et',
  Fi = 'fi',
  Fr = 'fr',
  FrBe = 'fr_BE',
  FrCh = 'fr_CH',
  He = 'he',
  Hi = 'hi',
  Hr = 'hr',
  Hu = 'hu',
  Id = 'id',
  It = 'it',
  ItCh = 'it_CH',
  Ja = 'ja',
  Jv = 'jv',
  Kk = 'kk',
  Ko = 'ko',
  Lt = 'lt',
  Lv = 'lv',
  Ms = 'ms',
  Mt = 'mt',
  Nb = 'nb',
  Nl = 'nl',
  NlBe = 'nl_BE',
  Nn = 'nn',
  Pl = 'pl',
  Pt = 'pt',
  PtBr = 'pt_BR',
  Ro = 'ro',
  Ru = 'ru',
  Sk = 'sk',
  Sl = 'sl',
  Sr = 'sr',
  Sv = 'sv',
  Th = 'th',
  Tl = 'tl',
  Tq = 'tq',
  Tr = 'tr',
  Uk = 'uk',
  Ur = 'ur',
  Vi = 'vi',
  ZhCn = 'zh_CN'
}

export type Payment = {
  __typename?: 'Payment';
  amountInCents: Scalars['Int'];
  createdAt: Scalars['ISO8601DateTime'];
  currency: CurrencyEnum;
  id: Scalars['ID'];
  invoice?: Maybe<Invoice>;
  status: PaymentStatusEnum;
  type: PaymentTypeEnum;
};

/** Autogenerated input type of PaymentMethodAttach */
export type PaymentMethodAttachInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  setupIntent: Scalars['String'];
};

/** Autogenerated return type of PaymentMethodAttach. */
export type PaymentMethodAttachPayload = {
  __typename?: 'PaymentMethodAttachPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  paymentMethod?: Maybe<CreditCard>;
  success: Scalars['Boolean'];
};

export enum PaymentMethodEnum {
  BankTransfer = 'bank_transfer',
  CreditCard = 'credit_card',
  Instalments = 'instalments'
}

export type PaymentPage = {
  __typename?: 'PaymentPage';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  nodes: Array<Payment>;
  nodesCount: Scalars['Int'];
  pagesCount: Scalars['Int'];
};

export enum PaymentSortAttributeEnum {
  AmountInCents = 'amount_in_cents',
  CreatedAt = 'created_at',
  Currency = 'currency',
  Id = 'id',
  Status = 'status'
}

export enum PaymentStatusEnum {
  Failed = 'failed',
  Initial = 'initial',
  PartiallyRefunded = 'partially_refunded',
  Refunded = 'refunded',
  Settled = 'settled'
}

export enum PaymentTypeEnum {
  BankTransfer = 'bank_transfer',
  CreditCardCharge = 'credit_card_charge'
}

export enum PaymentVariantEnum {
  Invoice = 'invoice',
  PaymentGateway = 'payment_gateway',
  TolqPays = 'tolq_pays'
}

export type PoolFileData = {
  __typename?: 'PoolFileData';
  errorMessage?: Maybe<Scalars['String']>;
  fileData?: Maybe<Scalars['JSON']>;
  fileId?: Maybe<Scalars['ID']>;
  hasErrors: Scalars['Boolean'];
  processing: Scalars['Boolean'];
  progress: Scalars['Int'];
};

export type PricingData = {
  __typename?: 'PricingData';
  currency?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  priceNoCurrency?: Maybe<Scalars['Float']>;
  pricePerWord?: Maybe<Scalars['String']>;
  pricePerWordNoCurrency?: Maybe<Scalars['Float']>;
  savings?: Maybe<Scalars['String']>;
};

export enum ProcedureNameEnum {
  ClientAcceptAll = 'client_accept_all'
}

export type ProcedureStatus = {
  __typename?: 'ProcedureStatus';
  errors?: Maybe<Array<Scalars['String']>>;
  isFinished?: Maybe<Scalars['Boolean']>;
  progressPct?: Maybe<Scalars['Int']>;
  secondsElapsed?: Maybe<Scalars['Int']>;
};

export type ProductivityMetrics = {
  __typename?: 'ProductivityMetrics';
  change?: Maybe<Scalars['Float']>;
  chartData?: Maybe<Array<ProductivityMetricsChartData>>;
  score?: Maybe<Scalars['Float']>;
};

export type ProductivityMetricsChartData = {
  __typename?: 'ProductivityMetricsChartData';
  timestamp?: Maybe<Scalars['ISO8601DateTime']>;
  value?: Maybe<Scalars['Float']>;
};

export enum ProductivityMetricsResolutionEnum {
  Daily = 'daily'
}

export enum ProofreaderVerdictEnum {
  BadQuality = 'bad_quality',
  BasicQuality = 'basic_quality',
  ExpertQuality = 'expert_quality',
  PremiumQuality = 'premium_quality',
  StandardQuality = 'standard_quality'
}

export type PurchaseOrder = {
  __typename?: 'PurchaseOrder';
  actualUsdCost?: Maybe<Scalars['Float']>;
  billingAccount: BillingAccount;
  cancelable: Scalars['Boolean'];
  canceledAt?: Maybe<Scalars['ISO8601DateTime']>;
  clientAccount: ClientAccount;
  createdAt: Scalars['ISO8601DateTime'];
  currency: Scalars['String'];
  id: Scalars['ID'];
  invoicedAt?: Maybe<Scalars['ISO8601DateTime']>;
  orderCode?: Maybe<Scalars['String']>;
  orders: Array<Order>;
  originalActualUsdCost?: Maybe<Scalars['Float']>;
  originalPrice: Scalars['Float'];
  originalTaxAmount: Scalars['Float'];
  paidAt?: Maybe<Scalars['ISO8601DateTime']>;
  payableAmount: Scalars['Float'];
  paymentStatus: PurchaseOrderPaymentStatusEnum;
  paymentVariant: PaymentVariantEnum;
  payments: Array<Payment>;
  price: Scalars['Float'];
  quality: QualityEnum;
  sourceLanguageCode: Scalars['String'];
  taxAmount: Scalars['Float'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated input type of PurchaseOrderCancel */
export type PurchaseOrderCancelInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  purchaseOrderId: Scalars['ID'];
};

/** Autogenerated return type of PurchaseOrderCancel. */
export type PurchaseOrderCancelPayload = {
  __typename?: 'PurchaseOrderCancelPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type PurchaseOrderPage = {
  __typename?: 'PurchaseOrderPage';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  nodes: Array<PurchaseOrder>;
  nodesCount: Scalars['Int'];
  pagesCount: Scalars['Int'];
};

export enum PurchaseOrderPaymentStatusEnum {
  Failed = 'failed',
  Na = 'na',
  Pending = 'pending',
  Settled = 'settled'
}

/** Autogenerated input type of PurchaseOrderRetry */
export type PurchaseOrderRetryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  purchaseOrderId: Scalars['ID'];
};

/** Autogenerated return type of PurchaseOrderRetry. */
export type PurchaseOrderRetryPayload = {
  __typename?: 'PurchaseOrderRetryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export enum PurchaseOrderSortAttributeEnum {
  CreatedAt = 'created_at',
  Id = 'id',
  Price = 'price',
  Quality = 'quality'
}

export enum PurposeEnum {
  Branding = 'branding',
  Educational = 'educational',
  Internal = 'internal',
  Legal = 'legal',
  Marketing = 'marketing',
  ProductDescriptions = 'product_descriptions',
  Seo = 'seo',
  TechnicalDocumentation = 'technical_documentation',
  Website = 'website'
}

export enum QualityEnum {
  Expert = 'expert',
  Localization = 'localization',
  Machine = 'machine',
  Postediting = 'postediting',
  PosteditingAi = 'postediting_ai',
  Translation = 'translation'
}

export type Query = {
  __typename?: 'Query';
  _machineTranslationEngine?: Maybe<MachineTranslator>;
  /** Billing account */
  billingAccount: BillingAccount;
  /** Client account */
  clientAccount: ClientAccount;
  clientInvite?: Maybe<ClientInvite>;
  /** Tolq configuration */
  config: TolqConfig;
  contextLibraryTags: Array<ContextLibraryTagType>;
  contextLibraryUploadedFiles: ContextLibraryUploadedFileTypePage;
  /** Current user */
  currentUser: User;
  generatedContents: GeneratedContentPage;
  /** Search gloassary terms */
  glossarySearch: GlossarySearch;
  hubspotIntegrationPages?: Maybe<HubspotPagePage>;
  /** A single invoice */
  invoice: Invoice;
  /** Invoices collection */
  invoices: InvoicePage;
  memberInvite?: Maybe<MemberInvite>;
  /** A single payment */
  payment: Payment;
  /** Payments collection */
  payments: PaymentPage;
  pollFileData?: Maybe<Array<PoolFileData>>;
  productivityMetrics: ProductivityMetrics;
  /** List of orders */
  purchaseOrders?: Maybe<PurchaseOrderPage>;
  /** Tolq realtime config */
  realtime: TolqRealtime;
  recentActivities: RecentActivityPage;
  /** Collection of TR segments */
  segments: Array<Segment>;
  /** Task hidden (omitted) content */
  taskFoldedContent?: Maybe<Array<TaskFoldedContent>>;
  /** List of tasks */
  tasks?: Maybe<TaskPage>;
  tmuData?: Maybe<Tmu>;
  /** A single transaction */
  transaction: Transaction;
  /** Transaction statistics */
  transactionStats: Array<TransactionStats>;
  /** Transactions collection */
  transactions: TransactionPage;
  /** A single translation request */
  translationRequest?: Maybe<TranslationRequest>;
  translationRequestBulkDownloadStatus: BulkDownloadStatus;
  translationRequestFileOrderDetails?: Maybe<FileOrderDetails>;
  /** Translation request collection */
  translationRequests: TranslationRequestPage;
  universalChatThreads: ThreadPage;
};

export type QueryClientInviteArgs = {
  token: Scalars['String'];
};

export type QueryContextLibraryUploadedFilesArgs = {
  id?: InputMaybe<Scalars['ID']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  skipPagination?: InputMaybe<Scalars['Boolean']>;
  sortAttribute?: InputMaybe<ContextLibraryUploadedFileSortAttributeEnum>;
  sortOrder?: InputMaybe<SortOrderEnum>;
  tagId?: InputMaybe<Scalars['ID']>;
};

export type QueryGeneratedContentsArgs = {
  contentTypeFilter?: InputMaybe<GeneratedContentTypeEnum>;
  id?: InputMaybe<Scalars['ID']>;
  languageFilter?: InputMaybe<Array<OrderableTargetLanguageCodesEnum>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  skipPagination?: InputMaybe<Scalars['Boolean']>;
  sortAttribute?: InputMaybe<GeneratedContentSortAttributeEnum>;
  sortOrder?: InputMaybe<SortOrderEnum>;
};

export type QueryGlossarySearchArgs = {
  glossaryId?: InputMaybe<Scalars['ID']>;
  page?: InputMaybe<Scalars['Int']>;
  search: Scalars['String'];
  showSuggestions?: InputMaybe<Scalars['Boolean']>;
};

export type QueryHubspotIntegrationPagesArgs = {
  languageFilter?: InputMaybe<Array<OrderableTargetLanguageCodesEnum>>;
  page?: InputMaybe<Scalars['Int']>;
  pageId?: InputMaybe<Scalars['ID']>;
  pageType?: InputMaybe<HubspotPageTypeEnum>;
  perPage?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  skipPagination?: InputMaybe<Scalars['Boolean']>;
  sortAttribute?: InputMaybe<HubspotPageSortAttributeEnum>;
  sortOrder?: InputMaybe<SortOrderEnum>;
};

export type QueryInvoiceArgs = {
  id: Scalars['ID'];
};

export type QueryInvoicesArgs = {
  overdue?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  paid?: InputMaybe<Scalars['Boolean']>;
  perPage?: InputMaybe<Scalars['Int']>;
  skipPagination?: InputMaybe<Scalars['Boolean']>;
  sortAttribute?: InputMaybe<InvoiceSortAttributeEnum>;
  sortOrder?: InputMaybe<SortOrderEnum>;
};

export type QueryMemberInviteArgs = {
  token: Scalars['String'];
};

export type QueryPaymentArgs = {
  id: Scalars['ID'];
};

export type QueryPaymentsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  skipPagination?: InputMaybe<Scalars['Boolean']>;
  sortAttribute?: InputMaybe<PaymentSortAttributeEnum>;
  sortOrder?: InputMaybe<SortOrderEnum>;
};

export type QueryPollFileDataArgs = {
  fileType?: InputMaybe<FileTypeEnum>;
  uids: Array<Scalars['ID']>;
};

export type QueryProductivityMetricsArgs = {
  fromDate?: InputMaybe<Scalars['ISO8601DateTime']>;
  resolution?: InputMaybe<ProductivityMetricsResolutionEnum>;
  toDate?: InputMaybe<Scalars['ISO8601DateTime']>;
};

export type QueryPurchaseOrdersArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  skipPagination?: InputMaybe<Scalars['Boolean']>;
  sortAttribute?: InputMaybe<PurchaseOrderSortAttributeEnum>;
  sortOrder?: InputMaybe<SortOrderEnum>;
};

export type QueryRecentActivitiesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  skipPagination?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySegmentsArgs = {
  translationRequestVersion: Scalars['ID'];
};

export type QueryTaskFoldedContentArgs = {
  foldIndex?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<TaskSequenceEnum>;
  taskId: Scalars['ID'];
};

export type QueryTasksArgs = {
  comments?: InputMaybe<CommentsFilterEnum>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  skipPagination?: InputMaybe<Scalars['Boolean']>;
  sortAttribute?: InputMaybe<TaskSortAttributeEnum>;
  sortOrder?: InputMaybe<SortOrderEnum>;
  sourceLanguageCode?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<TaskQueryStatusEnum>>;
  targetLanguageCode?: InputMaybe<Scalars['String']>;
  taskId?: InputMaybe<Scalars['ID']>;
  translationRequestVersion?: InputMaybe<Scalars['ID']>;
};

export type QueryTmuDataArgs = {
  currentLanguagePair?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};

export type QueryTransactionArgs = {
  id: Scalars['ID'];
};

export type QueryTransactionStatsArgs = {
  createdAtAfter?: InputMaybe<Scalars['ISO8601DateTime']>;
  createdAtBefore?: InputMaybe<Scalars['ISO8601DateTime']>;
  invoiceId?: InputMaybe<Scalars['ID']>;
  poCode?: InputMaybe<Scalars['String']>;
  quality?: InputMaybe<QualityEnum>;
  query?: InputMaybe<Scalars['String']>;
  sortAttribute?: InputMaybe<TransactionSortAttributeEnum>;
  sortOrder?: InputMaybe<SortOrderEnum>;
  sourceLanguageCode?: InputMaybe<Scalars['String']>;
  targetLanguageCode?: InputMaybe<Scalars['String']>;
  transactionType?: InputMaybe<TransactionTypeEnum>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type QueryTransactionsArgs = {
  createdAtAfter?: InputMaybe<Scalars['ISO8601DateTime']>;
  createdAtBefore?: InputMaybe<Scalars['ISO8601DateTime']>;
  invoiceId?: InputMaybe<Scalars['ID']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  poCode?: InputMaybe<Scalars['String']>;
  quality?: InputMaybe<QualityEnum>;
  query?: InputMaybe<Scalars['String']>;
  skipPagination?: InputMaybe<Scalars['Boolean']>;
  sortAttribute?: InputMaybe<TransactionSortAttributeEnum>;
  sortOrder?: InputMaybe<SortOrderEnum>;
  sourceLanguageCode?: InputMaybe<Scalars['String']>;
  targetLanguageCode?: InputMaybe<Scalars['String']>;
  transactionType?: InputMaybe<TransactionTypeEnum>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type QueryTranslationRequestArgs = {
  version: Scalars['ID'];
};

export type QueryTranslationRequestBulkDownloadStatusArgs = {
  guid: Scalars['String'];
};

export type QueryTranslationRequestFileOrderDetailsArgs = {
  guid: Scalars['String'];
};

export type QueryTranslationRequestsArgs = {
  comments?: InputMaybe<CommentsFilterEnum>;
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']>;
  createdBefore?: InputMaybe<Scalars['ISO8601DateTime']>;
  editorId?: InputMaybe<Scalars['ID']>;
  integration?: InputMaybe<IntegrationEnum>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  proposedClientDeadlineAfter?: InputMaybe<Scalars['ISO8601DateTime']>;
  proposedClientDeadlineBefore?: InputMaybe<Scalars['ISO8601DateTime']>;
  quality?: InputMaybe<QualityEnum>;
  query?: InputMaybe<Scalars['String']>;
  showDuplicates?: InputMaybe<Scalars['Boolean']>;
  skipPagination?: InputMaybe<Scalars['Boolean']>;
  sortAttribute?: InputMaybe<PaymentSortAttributeEnum>;
  sortOrder?: InputMaybe<SortOrderEnum>;
  sourceLanguageCode?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<InputMaybe<TranslationRequestStatusFilterEnum>>>;
  targetLanguageCode?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryUniversalChatThreadsArgs = {
  id?: InputMaybe<Scalars['ID']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  skipPagination?: InputMaybe<Scalars['Boolean']>;
};

export type RecentActivity = {
  __typename?: 'RecentActivity';
  id: Scalars['ID'];
  key: Scalars['String'];
  parameters?: Maybe<Scalars['JSON']>;
  trackable: TrackableUnion;
};

export type RecentActivityPage = {
  __typename?: 'RecentActivityPage';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  nodes: Array<RecentActivity>;
  nodesCount: Scalars['Int'];
  pagesCount: Scalars['Int'];
};

/** Autogenerated input type of RejectPhrases */
export type RejectPhrasesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  formCorrectionText: Scalars['String'];
  selectedPhrasesToCorrect: Array<Scalars['String']>;
  taskId: Scalars['ID'];
};

/** Autogenerated return type of RejectPhrases. */
export type RejectPhrasesPayload = {
  __typename?: 'RejectPhrasesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  comment?: Maybe<Comment>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
  taskStatus?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of RemoveLanguage */
export type RemoveLanguageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  languageCode: Scalars['String'];
};

/** Autogenerated return type of RemoveLanguage. */
export type RemoveLanguagePayload = {
  __typename?: 'RemoveLanguagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of ReplaceQuote */
export type ReplaceQuoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  translationRequestParams: TranslationRequestInput;
  translationRequestVersion: Scalars['ID'];
};

/** Autogenerated return type of ReplaceQuote. */
export type ReplaceQuotePayload = {
  __typename?: 'ReplaceQuotePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
  translationRequest?: Maybe<TranslationRequest>;
};

export enum RoleEnum {
  Admin = 'admin',
  Editor = 'editor',
  Manager = 'manager',
  Standard = 'standard'
}

export type Section = {
  __typename?: 'Section';
  included?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<SectionItem>>;
  sectionKey?: Maybe<Scalars['String']>;
};

export type SectionItem = {
  __typename?: 'SectionItem';
  extraInfo?: Maybe<Scalars['Boolean']>;
  included?: Maybe<Scalars['Boolean']>;
  itemKey?: Maybe<Scalars['String']>;
  selected?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type SectionLanguage = {
  __typename?: 'SectionLanguage';
  languageCode: Scalars['String'];
  section: Array<Section>;
};

export type Segment = {
  __typename?: 'Segment';
  index: Scalars['ID'];
  key: Scalars['String'];
  sentenceData: Array<SentenceData>;
  version?: Maybe<Scalars['ID']>;
};

export type SegmentUpdateInput = {
  newTranslationText: Scalars['String'];
  ref: Scalars['String'];
};

export type SegmentsUpdateError = {
  __typename?: 'SegmentsUpdateError';
  error?: Maybe<Scalars['String']>;
  ref?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of SegmentsUpdate */
export type SegmentsUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  segments: Array<SegmentUpdateInput>;
  translationRequestVersion: Scalars['ID'];
};

/** Autogenerated return type of SegmentsUpdate. */
export type SegmentsUpdatePayload = {
  __typename?: 'SegmentsUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Maybe<SegmentsUpdateError>>>;
  success: Scalars['Boolean'];
};

export type SentenceData = {
  __typename?: 'SentenceData';
  correctingState?: Maybe<Scalars['String']>;
  matches: Array<Scalars['String']>;
  paragraph?: Maybe<Scalars['ID']>;
  ref: Scalars['String'];
  targetChars?: Maybe<Scalars['Int']>;
  targetWords?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  translatable: Scalars['Boolean'];
  translationText?: Maybe<Scalars['String']>;
  words?: Maybe<Scalars['Int']>;
};

export type Sentences = {
  __typename?: 'Sentences';
  orderableWordsCopy?: Maybe<Scalars['String']>;
  savings?: Maybe<Scalars['String']>;
  totalOrderableWords?: Maybe<Scalars['Int']>;
  totalWords?: Maybe<TotalWords>;
};

/** Autogenerated input type of SetupIntentCreate */
export type SetupIntentCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of SetupIntentCreate. */
export type SetupIntentCreatePayload = {
  __typename?: 'SetupIntentCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  clientSecret: Scalars['String'];
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of Signup */
export type SignupInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  termsAccepted: Scalars['Boolean'];
};

/** Autogenerated return type of Signup. */
export type SignupPayload = {
  __typename?: 'SignupPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
  user?: Maybe<User>;
};

export type Skill = {
  __typename?: 'Skill';
  createdAt: Scalars['ISO8601DateTime'];
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  perceivedQuality?: Maybe<Scalars['String']>;
  sendTrainingReminderAt?: Maybe<Scalars['ISO8601DateTime']>;
  sourceLanguageCode?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  targetLanguageCode?: Maybe<Scalars['String']>;
  training: Scalars['Boolean'];
  translatorAccount?: Maybe<TranslatorAccount>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum SortOrderEnum {
  Asc = 'asc',
  Desc = 'desc'
}

export enum StyleEnum {
  Casual = 'casual',
  Corporate = 'corporate',
  Formal = 'formal',
  Playful = 'playful',
  Professional = 'professional',
  Scentific = 'scentific'
}

export type StyleGuide = {
  __typename?: 'StyleGuide';
  approved: Scalars['Boolean'];
  approvedByClient: Scalars['Boolean'];
  approvedByOps: Scalars['Boolean'];
  availableLanguages?: Maybe<Array<Scalars['String']>>;
  clientAccount: ClientAccount;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  referenceId: Scalars['Int'];
  sections?: Maybe<Array<SectionLanguage>>;
  sourceLanguageCode?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated input type of StyleGuideApprove */
export type StyleGuideApproveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  styleGuideId: Scalars['ID'];
};

/** Autogenerated return type of StyleGuideApprove. */
export type StyleGuideApprovePayload = {
  __typename?: 'StyleGuideApprovePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of StyleGuideCreate */
export type StyleGuideCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nameForTranslator?: InputMaybe<Scalars['String']>;
  sections: StyleGuideSectionLanguageInput;
};

/** Autogenerated return type of StyleGuideCreate. */
export type StyleGuideCreatePayload = {
  __typename?: 'StyleGuideCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  styleGuide?: Maybe<StyleGuide>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of StyleGuideDestroy */
export type StyleGuideDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  styleGuideId: Scalars['ID'];
};

/** Autogenerated return type of StyleGuideDestroy. */
export type StyleGuideDestroyPayload = {
  __typename?: 'StyleGuideDestroyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type StyleGuideItemInput = {
  extraInfo?: InputMaybe<Scalars['Boolean']>;
  included?: InputMaybe<Scalars['Boolean']>;
  itemKey?: InputMaybe<Scalars['String']>;
  selected?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type StyleGuideSectionInput = {
  included: Scalars['Boolean'];
  items: Array<StyleGuideItemInput>;
  sectionKey: Scalars['String'];
};

export type StyleGuideSectionLanguageInput = {
  af?: InputMaybe<Array<StyleGuideSectionInput>>;
  ak?: InputMaybe<Array<StyleGuideSectionInput>>;
  all: Array<StyleGuideSectionInput>;
  am?: InputMaybe<Array<StyleGuideSectionInput>>;
  ar?: InputMaybe<Array<StyleGuideSectionInput>>;
  as?: InputMaybe<Array<StyleGuideSectionInput>>;
  ay?: InputMaybe<Array<StyleGuideSectionInput>>;
  az?: InputMaybe<Array<StyleGuideSectionInput>>;
  ba?: InputMaybe<Array<StyleGuideSectionInput>>;
  be?: InputMaybe<Array<StyleGuideSectionInput>>;
  bg?: InputMaybe<Array<StyleGuideSectionInput>>;
  bm?: InputMaybe<Array<StyleGuideSectionInput>>;
  bn?: InputMaybe<Array<StyleGuideSectionInput>>;
  bo?: InputMaybe<Array<StyleGuideSectionInput>>;
  bs?: InputMaybe<Array<StyleGuideSectionInput>>;
  ca?: InputMaybe<Array<StyleGuideSectionInput>>;
  cs?: InputMaybe<Array<StyleGuideSectionInput>>;
  cv?: InputMaybe<Array<StyleGuideSectionInput>>;
  cy?: InputMaybe<Array<StyleGuideSectionInput>>;
  da?: InputMaybe<Array<StyleGuideSectionInput>>;
  de?: InputMaybe<Array<StyleGuideSectionInput>>;
  de_AT?: InputMaybe<Array<StyleGuideSectionInput>>;
  de_CH?: InputMaybe<Array<StyleGuideSectionInput>>;
  de_DE?: InputMaybe<Array<StyleGuideSectionInput>>;
  dv?: InputMaybe<Array<StyleGuideSectionInput>>;
  dz?: InputMaybe<Array<StyleGuideSectionInput>>;
  ee?: InputMaybe<Array<StyleGuideSectionInput>>;
  el?: InputMaybe<Array<StyleGuideSectionInput>>;
  en?: InputMaybe<Array<StyleGuideSectionInput>>;
  en_AU?: InputMaybe<Array<StyleGuideSectionInput>>;
  en_CA?: InputMaybe<Array<StyleGuideSectionInput>>;
  en_GB?: InputMaybe<Array<StyleGuideSectionInput>>;
  en_US?: InputMaybe<Array<StyleGuideSectionInput>>;
  en_ZA?: InputMaybe<Array<StyleGuideSectionInput>>;
  eo?: InputMaybe<Array<StyleGuideSectionInput>>;
  es?: InputMaybe<Array<StyleGuideSectionInput>>;
  es_AR?: InputMaybe<Array<StyleGuideSectionInput>>;
  es_ES?: InputMaybe<Array<StyleGuideSectionInput>>;
  es_LA?: InputMaybe<Array<StyleGuideSectionInput>>;
  es_MX?: InputMaybe<Array<StyleGuideSectionInput>>;
  et?: InputMaybe<Array<StyleGuideSectionInput>>;
  eu?: InputMaybe<Array<StyleGuideSectionInput>>;
  fa?: InputMaybe<Array<StyleGuideSectionInput>>;
  ff?: InputMaybe<Array<StyleGuideSectionInput>>;
  fi?: InputMaybe<Array<StyleGuideSectionInput>>;
  fo?: InputMaybe<Array<StyleGuideSectionInput>>;
  fr?: InputMaybe<Array<StyleGuideSectionInput>>;
  fr_BE?: InputMaybe<Array<StyleGuideSectionInput>>;
  fr_CA?: InputMaybe<Array<StyleGuideSectionInput>>;
  fr_CH?: InputMaybe<Array<StyleGuideSectionInput>>;
  fr_FR?: InputMaybe<Array<StyleGuideSectionInput>>;
  fy?: InputMaybe<Array<StyleGuideSectionInput>>;
  ga?: InputMaybe<Array<StyleGuideSectionInput>>;
  gn?: InputMaybe<Array<StyleGuideSectionInput>>;
  gu?: InputMaybe<Array<StyleGuideSectionInput>>;
  ha?: InputMaybe<Array<StyleGuideSectionInput>>;
  he?: InputMaybe<Array<StyleGuideSectionInput>>;
  hi?: InputMaybe<Array<StyleGuideSectionInput>>;
  hr?: InputMaybe<Array<StyleGuideSectionInput>>;
  ht?: InputMaybe<Array<StyleGuideSectionInput>>;
  hu?: InputMaybe<Array<StyleGuideSectionInput>>;
  hy?: InputMaybe<Array<StyleGuideSectionInput>>;
  id?: InputMaybe<Array<StyleGuideSectionInput>>;
  ig?: InputMaybe<Array<StyleGuideSectionInput>>;
  ii?: InputMaybe<Array<StyleGuideSectionInput>>;
  is?: InputMaybe<Array<StyleGuideSectionInput>>;
  it?: InputMaybe<Array<StyleGuideSectionInput>>;
  it_CH?: InputMaybe<Array<StyleGuideSectionInput>>;
  ja?: InputMaybe<Array<StyleGuideSectionInput>>;
  jv?: InputMaybe<Array<StyleGuideSectionInput>>;
  ka?: InputMaybe<Array<StyleGuideSectionInput>>;
  kg?: InputMaybe<Array<StyleGuideSectionInput>>;
  ki?: InputMaybe<Array<StyleGuideSectionInput>>;
  kk?: InputMaybe<Array<StyleGuideSectionInput>>;
  km?: InputMaybe<Array<StyleGuideSectionInput>>;
  kn?: InputMaybe<Array<StyleGuideSectionInput>>;
  ko?: InputMaybe<Array<StyleGuideSectionInput>>;
  kr?: InputMaybe<Array<StyleGuideSectionInput>>;
  ks?: InputMaybe<Array<StyleGuideSectionInput>>;
  ku?: InputMaybe<Array<StyleGuideSectionInput>>;
  ky?: InputMaybe<Array<StyleGuideSectionInput>>;
  lb?: InputMaybe<Array<StyleGuideSectionInput>>;
  lg?: InputMaybe<Array<StyleGuideSectionInput>>;
  ln?: InputMaybe<Array<StyleGuideSectionInput>>;
  lo?: InputMaybe<Array<StyleGuideSectionInput>>;
  lt?: InputMaybe<Array<StyleGuideSectionInput>>;
  lu?: InputMaybe<Array<StyleGuideSectionInput>>;
  lv?: InputMaybe<Array<StyleGuideSectionInput>>;
  mg?: InputMaybe<Array<StyleGuideSectionInput>>;
  mi?: InputMaybe<Array<StyleGuideSectionInput>>;
  mk?: InputMaybe<Array<StyleGuideSectionInput>>;
  ml?: InputMaybe<Array<StyleGuideSectionInput>>;
  mn?: InputMaybe<Array<StyleGuideSectionInput>>;
  mr?: InputMaybe<Array<StyleGuideSectionInput>>;
  ms?: InputMaybe<Array<StyleGuideSectionInput>>;
  mt?: InputMaybe<Array<StyleGuideSectionInput>>;
  my?: InputMaybe<Array<StyleGuideSectionInput>>;
  nb?: InputMaybe<Array<StyleGuideSectionInput>>;
  ne?: InputMaybe<Array<StyleGuideSectionInput>>;
  nl?: InputMaybe<Array<StyleGuideSectionInput>>;
  nl_BE?: InputMaybe<Array<StyleGuideSectionInput>>;
  nl_NL?: InputMaybe<Array<StyleGuideSectionInput>>;
  nn?: InputMaybe<Array<StyleGuideSectionInput>>;
  no?: InputMaybe<Array<StyleGuideSectionInput>>;
  nv?: InputMaybe<Array<StyleGuideSectionInput>>;
  ny?: InputMaybe<Array<StyleGuideSectionInput>>;
  oc?: InputMaybe<Array<StyleGuideSectionInput>>;
  om?: InputMaybe<Array<StyleGuideSectionInput>>;
  or?: InputMaybe<Array<StyleGuideSectionInput>>;
  os?: InputMaybe<Array<StyleGuideSectionInput>>;
  pa?: InputMaybe<Array<StyleGuideSectionInput>>;
  pl?: InputMaybe<Array<StyleGuideSectionInput>>;
  ps?: InputMaybe<Array<StyleGuideSectionInput>>;
  pt?: InputMaybe<Array<StyleGuideSectionInput>>;
  pt_BR?: InputMaybe<Array<StyleGuideSectionInput>>;
  qu?: InputMaybe<Array<StyleGuideSectionInput>>;
  rm?: InputMaybe<Array<StyleGuideSectionInput>>;
  rn?: InputMaybe<Array<StyleGuideSectionInput>>;
  ro?: InputMaybe<Array<StyleGuideSectionInput>>;
  ru?: InputMaybe<Array<StyleGuideSectionInput>>;
  rw?: InputMaybe<Array<StyleGuideSectionInput>>;
  sd?: InputMaybe<Array<StyleGuideSectionInput>>;
  si?: InputMaybe<Array<StyleGuideSectionInput>>;
  sk?: InputMaybe<Array<StyleGuideSectionInput>>;
  sl?: InputMaybe<Array<StyleGuideSectionInput>>;
  sn?: InputMaybe<Array<StyleGuideSectionInput>>;
  so?: InputMaybe<Array<StyleGuideSectionInput>>;
  sq?: InputMaybe<Array<StyleGuideSectionInput>>;
  sr?: InputMaybe<Array<StyleGuideSectionInput>>;
  st?: InputMaybe<Array<StyleGuideSectionInput>>;
  su?: InputMaybe<Array<StyleGuideSectionInput>>;
  sv?: InputMaybe<Array<StyleGuideSectionInput>>;
  sw?: InputMaybe<Array<StyleGuideSectionInput>>;
  ta?: InputMaybe<Array<StyleGuideSectionInput>>;
  te?: InputMaybe<Array<StyleGuideSectionInput>>;
  tg?: InputMaybe<Array<StyleGuideSectionInput>>;
  th?: InputMaybe<Array<StyleGuideSectionInput>>;
  ti?: InputMaybe<Array<StyleGuideSectionInput>>;
  tk?: InputMaybe<Array<StyleGuideSectionInput>>;
  tl?: InputMaybe<Array<StyleGuideSectionInput>>;
  tn?: InputMaybe<Array<StyleGuideSectionInput>>;
  to?: InputMaybe<Array<StyleGuideSectionInput>>;
  tr?: InputMaybe<Array<StyleGuideSectionInput>>;
  ts?: InputMaybe<Array<StyleGuideSectionInput>>;
  tt?: InputMaybe<Array<StyleGuideSectionInput>>;
  tw?: InputMaybe<Array<StyleGuideSectionInput>>;
  ug?: InputMaybe<Array<StyleGuideSectionInput>>;
  uk?: InputMaybe<Array<StyleGuideSectionInput>>;
  ur?: InputMaybe<Array<StyleGuideSectionInput>>;
  uz?: InputMaybe<Array<StyleGuideSectionInput>>;
  vi?: InputMaybe<Array<StyleGuideSectionInput>>;
  vo?: InputMaybe<Array<StyleGuideSectionInput>>;
  wo?: InputMaybe<Array<StyleGuideSectionInput>>;
  xh?: InputMaybe<Array<StyleGuideSectionInput>>;
  yi?: InputMaybe<Array<StyleGuideSectionInput>>;
  za?: InputMaybe<Array<StyleGuideSectionInput>>;
  zh?: InputMaybe<Array<StyleGuideSectionInput>>;
  zh_CN?: InputMaybe<Array<StyleGuideSectionInput>>;
  zh_HK?: InputMaybe<Array<StyleGuideSectionInput>>;
  zh_TW?: InputMaybe<Array<StyleGuideSectionInput>>;
  zu?: InputMaybe<Array<StyleGuideSectionInput>>;
};

/** Autogenerated input type of StyleGuideUpdate */
export type StyleGuideUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  nameForTranslator?: InputMaybe<Scalars['String']>;
  sections: StyleGuideSectionLanguageInput;
  styleGuideId: Scalars['ID'];
};

/** Autogenerated return type of StyleGuideUpdate. */
export type StyleGuideUpdatePayload = {
  __typename?: 'StyleGuideUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  styleGuide?: Maybe<StyleGuide>;
  success: Scalars['Boolean'];
};

export type SummaryStats = {
  __typename?: 'SummaryStats';
  percentage?: Maybe<Scalars['Int']>;
  sentences?: Maybe<Scalars['Int']>;
  words?: Maybe<Scalars['Int']>;
};

export type Task = {
  __typename?: 'Task';
  actionTaken?: Maybe<Scalars['String']>;
  autoClientReview: Scalars['Boolean'];
  clientAccount: ClientAccount;
  closeAt?: Maybe<Scalars['ISO8601DateTime']>;
  comments?: Maybe<Array<Comment>>;
  completedAt?: Maybe<Scalars['ISO8601DateTime']>;
  confirmedAt?: Maybe<Scalars['ISO8601DateTime']>;
  correctorAccount?: Maybe<TranslatorAccount>;
  createdAt: Scalars['ISO8601DateTime'];
  creditAt?: Maybe<Scalars['ISO8601DateTime']>;
  creditedAt?: Maybe<Scalars['ISO8601DateTime']>;
  currency: CurrencyEnum;
  deadline?: Maybe<Scalars['ISO8601DateTime']>;
  doubleCheckEnabled: Scalars['Boolean'];
  earningsPerWordEur?: Maybe<Scalars['Float']>;
  earningsPerWordUsd?: Maybe<Scalars['Float']>;
  eventMode: Scalars['Boolean'];
  flaggedByAccount?: Maybe<TranslatorAccount>;
  highlightable: Scalars['Boolean'];
  highlightsConfirmedAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  invitations: Array<Invitation>;
  keystrokes?: Maybe<Scalars['Int']>;
  languagePair: Scalars['String'];
  machineTranslationEngine?: Maybe<NmtEngineEnum>;
  opsHandled: Scalars['Boolean'];
  orderQuality: QualityEnum;
  outputGeneratedAt?: Maybe<Scalars['ISO8601DateTime']>;
  precedentTrs?: Maybe<Array<TranslationRequest>>;
  progress?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  proofreaderAccount?: Maybe<TranslatorAccount>;
  proofreaderVerdict?: Maybe<ProofreaderVerdictEnum>;
  proofreaderVerdictText?: Maybe<Scalars['String']>;
  proofreadingDistance?: Maybe<Scalars['Int']>;
  proofreadingOverlapScore?: Maybe<Scalars['Float']>;
  proofreadingStartedAt?: Maybe<Scalars['ISO8601DateTime']>;
  proposedAction?: Maybe<Scalars['String']>;
  rejectingStartedAt?: Maybe<Scalars['ISO8601DateTime']>;
  requestRelation: Scalars['Int'];
  reroutedTask?: Maybe<Task>;
  reservedTranslatorAccount?: Maybe<TranslatorAccount>;
  sourceLanguageCode: Scalars['String'];
  sourceTitle?: Maybe<Scalars['String']>;
  spellcheckLocale?: Maybe<Scalars['String']>;
  spellingErrors?: Maybe<Scalars['Int']>;
  status: TaskStatusEnum;
  styleGuide: StyleGuide;
  targetLanguageCode: Scalars['String'];
  title: Scalars['String'];
  tooInactive: Scalars['Boolean'];
  totalSentenceCount?: Maybe<Scalars['Int']>;
  totalTranslatableCharCount?: Maybe<Scalars['Int']>;
  totalTranslatableWordCount?: Maybe<Scalars['Int']>;
  translationAdapterData?: Maybe<TranslationAdapterData>;
  translationStartedAt?: Maybe<Scalars['ISO8601DateTime']>;
  translatorAccount?: Maybe<TranslatorInterface>;
  translatorAnswered: Scalars['Boolean'];
  unreadCommentCount?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['ISO8601DateTime'];
  waitingInReviewSince?: Maybe<Scalars['ISO8601DateTime']>;
  workflow: QualityEnum;
};

export type TaskInvitationsArgs = {
  proofreading?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated input type of TaskAccept */
export type TaskAcceptInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  taskId: Scalars['ID'];
};

/** Autogenerated return type of TaskAccept. */
export type TaskAcceptPayload = {
  __typename?: 'TaskAcceptPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
  task?: Maybe<Task>;
};

export type TaskFoldedContent = {
  __typename?: 'TaskFoldedContent';
  source?: Maybe<Scalars['String']>;
  translation?: Maybe<Scalars['String']>;
};

export type TaskPage = {
  __typename?: 'TaskPage';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  nodes: Array<Task>;
  nodesCount: Scalars['Int'];
  pagesCount: Scalars['Int'];
};

export enum TaskQueryStatusEnum {
  Active = 'active',
  Adjourned = 'adjourned',
  ClientReview = 'client_review',
  Correcting = 'correcting',
  Initialized = 'initialized'
}

export enum TaskRelationEnum {
  ManyRequests = 'many_requests',
  ManyTasks = 'many_tasks',
  OneOnOne = 'one_on_one',
  UnknownKind = 'unknown_kind'
}

export enum TaskSequenceEnum {
  Next = 'next',
  Previous = 'previous'
}

export enum TaskSortAttributeEnum {
  ActionTaken = 'action_taken',
  AutoClientReview = 'auto_client_review',
  CloseAt = 'close_at',
  CompletedAt = 'completed_at',
  ConfirmedAt = 'confirmed_at',
  CreatedAt = 'created_at',
  CreditAt = 'credit_at',
  CreditedAt = 'credited_at',
  Currency = 'currency',
  Deadline = 'deadline',
  DoubleCheckEnabled = 'double_check_enabled',
  EarningsPerWordEur = 'earnings_per_word_eur',
  EarningsPerWordUsd = 'earnings_per_word_usd',
  EventMode = 'event_mode',
  Highlightable = 'highlightable',
  HighlightsConfirmedAt = 'highlights_confirmed_at',
  Id = 'id',
  Keystrokes = 'keystrokes',
  MachineTranslationEngine = 'machine_translation_engine',
  OpsHandled = 'ops_handled',
  OutputGeneratedAt = 'output_generated_at',
  ProjectName = 'project_name',
  ProofreaderConfirmedAt = 'proofreader_confirmed_at',
  ProofreaderVerdict = 'proofreader_verdict',
  ProofreaderVerdictText = 'proofreader_verdict_text',
  ProofreadingDistance = 'proofreading_distance',
  ProofreadingOverlapScore = 'proofreading_overlap_score',
  ProofreadingStartedAt = 'proofreading_started_at',
  ProposedAction = 'proposed_action',
  RejectingStartedAt = 'rejecting_started_at',
  RequestRelation = 'request_relation',
  SourceLanguageCode = 'source_language_code',
  SourceTitle = 'source_title',
  SpellcheckLocale = 'spellcheck_locale',
  SpellingErrors = 'spelling_errors',
  Status = 'status',
  TargetLanguageCode = 'target_language_code',
  TotalSentenceCount = 'total_sentence_count',
  TotalTranslatableCharCount = 'total_translatable_char_count',
  TotalTranslatableWordCount = 'total_translatable_word_count',
  TranslationStartedAt = 'translation_started_at',
  TranslatorAnswered = 'translator_answered',
  UpdatedAt = 'updated_at',
  WaitingForClientSince = 'waiting_for_client_since',
  WaitingInReviewSince = 'waiting_in_review_since',
  Workflow = 'workflow'
}

export enum TaskStatusEnum {
  Adjourned = 'adjourned',
  Buffering = 'buffering',
  ClientReview = 'client_review',
  Confirmed = 'confirmed',
  Correcting = 'correcting',
  Finished = 'finished',
  Initialized = 'initialized',
  Paused = 'paused',
  Proofreading = 'proofreading',
  Rerouted = 'rerouted',
  Rerouting = 'rerouting',
  TaskTranslating = 'task_translating',
  WaitingForMachineTranslations = 'waiting_for_machine_translations'
}

/** Autogenerated input type of TermAdd */
export type TermAddInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  glossaryId: Scalars['ID'];
  term: Scalars['JSON'];
};

/** Autogenerated return type of TermAdd. */
export type TermAddPayload = {
  __typename?: 'TermAddPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
  term?: Maybe<GlossaryTerm>;
};

/** Autogenerated input type of TermDestroy */
export type TermDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  glossaryId: Scalars['ID'];
  termId: Scalars['ID'];
};

/** Autogenerated return type of TermDestroy. */
export type TermDestroyPayload = {
  __typename?: 'TermDestroyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of TermInclude */
export type TermIncludeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  glossaryId: Scalars['ID'];
  termId: Scalars['ID'];
};

/** Autogenerated return type of TermInclude. */
export type TermIncludePayload = {
  __typename?: 'TermIncludePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of TermUpdate */
export type TermUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  definition: Scalars['JSON'];
  glossaryId: Scalars['ID'];
  termId: Scalars['ID'];
};

/** Autogenerated return type of TermUpdate. */
export type TermUpdatePayload = {
  __typename?: 'TermUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
  term?: Maybe<GlossaryTerm>;
};

export type Thread = {
  __typename?: 'Thread';
  contexts?: Maybe<Array<UniversalChatContextType>>;
  createdAt: Scalars['ISO8601DateTime'];
  engine: UniversalChatEngineEnum;
  id: Scalars['ID'];
  messages?: Maybe<MessagePage>;
  title: Scalars['String'];
  tokens?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ThreadMessagesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  skipPagination?: InputMaybe<Scalars['Boolean']>;
};

export type ThreadPage = {
  __typename?: 'ThreadPage';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  nodes: Array<Thread>;
  nodesCount: Scalars['Int'];
  pagesCount: Scalars['Int'];
};

export type Tmu = {
  __typename?: 'Tmu';
  data: TmuData;
  staticData: TmuStaticData;
};

export type TmuData = {
  __typename?: 'TmuData';
  currentLanguagePair?: Maybe<Scalars['String']>;
  hasTerms: Scalars['Boolean'];
  languagePairs?: Maybe<Array<TmuLanguagePair>>;
  pages: Scalars['Int'];
  terms: Array<TmuTerm>;
};

export type TmuLanguagePair = {
  __typename?: 'TmuLanguagePair';
  codes?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type TmuStaticData = {
  __typename?: 'TmuStaticData';
  allowedTypes: Array<Scalars['String']>;
};

export type TmuTerm = {
  __typename?: 'TmuTerm';
  liveTranslationId: Scalars['ID'];
  originalText?: Maybe<Scalars['String']>;
  sourceLanguageCode: Scalars['String'];
  targetLanguageCode: Scalars['String'];
  translationText?: Maybe<Scalars['String']>;
};

export type TolqConfig = {
  __typename?: 'TolqConfig';
  bic: Scalars['String'];
  iban: Scalars['String'];
  kvk: Scalars['String'];
  vat: Scalars['String'];
};

export type TolqRealtime = {
  __typename?: 'TolqRealtime';
  socketUrl: Scalars['String'];
  token: Scalars['String'];
};

export enum ToneEnum {
  Authoritative = 'authoritative',
  Friendly = 'friendly',
  Humorous = 'humorous',
  Informative = 'informative',
  Serious = 'serious'
}

export type TotalWords = {
  __typename?: 'TotalWords';
  perLanguage?: Maybe<Scalars['Int']>;
  targetLanguagesCount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type TrackableUnion = Comment | GeneratedContent | Thread | TranslationRequest;

export type Transaction = {
  __typename?: 'Transaction';
  amount: Scalars['Float'];
  createdAt: Scalars['ISO8601DateTime'];
  currency: CurrencyEnum;
  id: Scalars['ID'];
  invoice?: Maybe<Invoice>;
  invoiceId?: Maybe<Scalars['ID']>;
  purchaseOrderCode?: Maybe<Scalars['String']>;
  quality?: Maybe<QualityEnum>;
  sourceLanguageCode?: Maybe<Scalars['String']>;
  targetLanguageCode?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  transactionType: TransactionTypeEnum;
  user?: Maybe<User>;
};

export type TransactionPage = {
  __typename?: 'TransactionPage';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  nodes: Array<Transaction>;
  nodesCount: Scalars['Int'];
  pagesCount: Scalars['Int'];
};

export enum TransactionSortAttributeEnum {
  Amount = 'amount',
  CreatedAt = 'created_at',
  Currency = 'currency',
  Id = 'id',
  InvoiceId = 'invoice_id',
  LanguagePair = 'language_pair',
  Quality = 'quality',
  SourceLanguageCode = 'source_language_code',
  TargetLanguageCode = 'target_language_code',
  TransactionType = 'transaction_type',
  UserId = 'user_id'
}

export type TransactionStats = {
  __typename?: 'TransactionStats';
  balance: Scalars['Float'];
  currency: CurrencyEnum;
};

export enum TransactionTypeEnum {
  CurrencyChange = 'currency_change',
  ManualCredit = 'manual_credit',
  ManualDebit = 'manual_debit',
  Payment = 'payment',
  PaymentAi = 'payment_ai',
  PaymentRefund = 'payment_refund',
  Payout = 'payout',
  PayoutJudge = 'payout_judge',
  Purchase = 'purchase',
  PurchaseAi = 'purchase_ai',
  PurchaseRefund = 'purchase_refund',
  SubscriptionPayment = 'subscription_payment',
  Unknown = 'unknown',
  Withdrawal = 'withdrawal',
  WithdrawalFee = 'withdrawal_fee'
}

export type TransactionsDownloadFilterInput = {
  createdAtAfter?: InputMaybe<Scalars['ISO8601DateTime']>;
  createdAtBefore?: InputMaybe<Scalars['ISO8601DateTime']>;
  invoiceId?: InputMaybe<Scalars['ID']>;
  poCode?: InputMaybe<Scalars['String']>;
  quality?: InputMaybe<QualityEnum>;
  query?: InputMaybe<Scalars['String']>;
  sourceLanguageCode?: InputMaybe<Scalars['String']>;
  targetLanguageCode?: InputMaybe<Scalars['String']>;
  transactionType?: InputMaybe<TransactionTypeEnum>;
  userId?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated input type of TransactionsDownload */
export type TransactionsDownloadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  filters: TransactionsDownloadFilterInput;
  shownKeys: Array<Scalars['String']>;
  sortAttribute?: InputMaybe<TransactionSortAttributeEnum>;
  sortOrder?: InputMaybe<SortOrderEnum>;
};

/** Autogenerated return type of TransactionsDownload. */
export type TransactionsDownloadPayload = {
  __typename?: 'TransactionsDownloadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  csvData?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type Translation = {
  __typename?: 'Translation';
  belongsToTask?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Scalars['String']>>;
  initialTranslationText?: Maybe<Scalars['String']>;
  originalText?: Maybe<Scalars['String']>;
  ref?: Maybe<Scalars['String']>;
  rejectMessage?: Maybe<Scalars['String']>;
  reportedMessage?: Maybe<Scalars['String']>;
  sourceChunkId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  translationText?: Maybe<Scalars['String']>;
};

export type TranslationAdapterData = {
  __typename?: 'TranslationAdapterData';
  keysData?: Maybe<Array<KeyData>>;
  translationData?: Maybe<Array<TranslationData>>;
  translations?: Maybe<Array<Translation>>;
};

export type TranslationData = {
  __typename?: 'TranslationData';
  activeTranslation?: Maybe<Scalars['String']>;
  correctingState?: Maybe<Scalars['String']>;
  flaggedBy?: Maybe<Scalars['Int']>;
  flaggedMessage?: Maybe<Scalars['String']>;
  foldIndex?: Maybe<Scalars['Int']>;
  folded?: Maybe<Scalars['Boolean']>;
  index?: Maybe<Scalars['Int']>;
  interactive?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Scalars['String']>;
  machineText?: Maybe<Scalars['String']>;
  matches?: Maybe<Array<Scalars['String']>>;
  notYetTranslated?: Maybe<Scalars['Boolean']>;
  paragraph?: Maybe<Scalars['Int']>;
  phrases?: Maybe<Scalars['Int']>;
  proofreader?: Maybe<Scalars['Int']>;
  proofreadingText?: Maybe<Scalars['String']>;
  ref?: Maybe<Scalars['String']>;
  rejectMessage?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  targetChars?: Maybe<Scalars['Int']>;
  targetWords?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  translatable?: Maybe<Scalars['Boolean']>;
  translationText?: Maybe<Scalars['String']>;
  translator?: Maybe<Scalars['Int']>;
  uuid?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
  words?: Maybe<Scalars['Int']>;
};

/** Autogenerated input type of TranslationMemoryDestroy */
export type TranslationMemoryDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  liveTranslationId: Scalars['ID'];
};

/** Autogenerated return type of TranslationMemoryDestroy. */
export type TranslationMemoryDestroyPayload = {
  __typename?: 'TranslationMemoryDestroyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of TranslationMemoryUpdate */
export type TranslationMemoryUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  liveTranslationId: Scalars['ID'];
  translation: Scalars['String'];
};

/** Autogenerated return type of TranslationMemoryUpdate. */
export type TranslationMemoryUpdatePayload = {
  __typename?: 'TranslationMemoryUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type TranslationRequest = {
  __typename?: 'TranslationRequest';
  adjustedQuality?: Maybe<Scalars['String']>;
  apiMachineRequest?: Maybe<Scalars['Boolean']>;
  apiRequest?: Maybe<Scalars['Boolean']>;
  assignedGlossariesIds?: Maybe<Array<Scalars['ID']>>;
  autoClientReview?: Maybe<Scalars['Boolean']>;
  billingAccount: BillingAccount;
  callbackUrl?: Maybe<Scalars['String']>;
  callbacks?: Maybe<Array<Callback>>;
  canBeAccepted: Scalars['Boolean'];
  comment?: Maybe<Scalars['String']>;
  comments?: Maybe<Array<Comment>>;
  completed?: Maybe<Scalars['Boolean']>;
  completedAt?: Maybe<Scalars['ISO8601DateTime']>;
  completionEmailSent: Scalars['Boolean'];
  contextUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  creditedAt?: Maybe<Scalars['ISO8601DateTime']>;
  dependenciesAdjournedRequest?: Maybe<Array<Scalars['Int']>>;
  detectedSourceLanguage?: Maybe<Scalars['String']>;
  duplicateOf?: Maybe<Scalars['ID']>;
  eventMode?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Scalars['String']>;
  finishedTasks?: Maybe<Array<Task>>;
  glossaries?: Maybe<Array<Glossary>>;
  hubspotTranslations?: Maybe<Array<HubspotTranslation>>;
  id: Scalars['ID'];
  integration?: Maybe<IntegrationEnum>;
  invoicePaymentPending: Scalars['Boolean'];
  multipleFiles?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  orderDetails?: Maybe<OrderDetails>;
  ordered: Scalars['Boolean'];
  paymentFailed: Scalars['Boolean'];
  pendingTasks?: Maybe<Array<Task>>;
  plainText: Scalars['Boolean'];
  /** Procedure status of eg translationRequestAcceptAll */
  procedureStatus?: Maybe<ProcedureStatus>;
  proposedClientDeadline?: Maybe<Scalars['ISO8601DateTime']>;
  pully?: Maybe<Scalars['Boolean']>;
  purchaseOrder?: Maybe<PurchaseOrder>;
  purchaseOrderCode?: Maybe<Scalars['String']>;
  quality: QualityEnum;
  requestType: TranslationRequestTypeEnum;
  sourceLanguageCode: Scalars['String'];
  status: TranslationRequestStatusEnum;
  styleGuide?: Maybe<StyleGuide>;
  styleGuideReferenceId?: Maybe<Scalars['ID']>;
  targetLanguageCode: Scalars['String'];
  taskRelation: TaskRelationEnum;
  tasks: Array<Task>;
  title?: Maybe<Scalars['String']>;
  tolqPays: Scalars['Boolean'];
  totalOrderedWordCount?: Maybe<Scalars['Int']>;
  totalWordCount?: Maybe<Scalars['Int']>;
  translationReady: Scalars['Boolean'];
  updatedAt: Scalars['ISO8601DateTime'];
  uploaded?: Maybe<Scalars['Boolean']>;
  user: User;
  version: Scalars['ID'];
  waitingForClientSince?: Maybe<Scalars['ISO8601DateTime']>;
  waitingInReviewSince?: Maybe<Scalars['ISO8601DateTime']>;
};

export type TranslationRequestProcedureStatusArgs = {
  procedureName: ProcedureNameEnum;
};

/** Autogenerated input type of TranslationRequestAcceptAll */
export type TranslationRequestAcceptAllInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  translationRequestVersion: Array<Scalars['ID']>;
};

/** Autogenerated return type of TranslationRequestAcceptAll. */
export type TranslationRequestAcceptAllPayload = {
  __typename?: 'TranslationRequestAcceptAllPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  procedureStatus?: Maybe<Array<ProcedureStatus>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of TranslationRequestBulDownload */
export type TranslationRequestBulDownloadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  format?: InputMaybe<BulkDownloadFormatEnum>;
  translationRequestVersion: Array<Scalars['ID']>;
};

/** Autogenerated return type of TranslationRequestBulDownload. */
export type TranslationRequestBulDownloadPayload = {
  __typename?: 'TranslationRequestBulDownloadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  guid: Scalars['String'];
  success: Scalars['Boolean'];
};

/** Autogenerated input type of TranslationRequestBulkOrder */
export type TranslationRequestBulkOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  translationRequestVersion: Array<Scalars['ID']>;
};

/** Autogenerated return type of TranslationRequestBulkOrder. */
export type TranslationRequestBulkOrderPayload = {
  __typename?: 'TranslationRequestBulkOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of TranslationRequestDestroy */
export type TranslationRequestDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  translationRequestVersion: Scalars['ID'];
};

/** Autogenerated return type of TranslationRequestDestroy. */
export type TranslationRequestDestroyPayload = {
  __typename?: 'TranslationRequestDestroyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export enum TranslationRequestDownloadFormatEnum {
  Json = 'json',
  Plain = 'plain',
  Pully = 'pully',
  Zip = 'zip'
}

export type TranslationRequestInput = {
  assignedGlossariesIds?: InputMaybe<Array<Scalars['ID']>>;
  autoClientReview?: InputMaybe<Scalars['Boolean']>;
  callbackUrl?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  contentSource?: InputMaybe<ContentSourceEnum>;
  contextUrl?: InputMaybe<Scalars['String']>;
  copy?: InputMaybe<Scalars['String']>;
  editorIdsPerLanguage?: InputMaybe<Scalars['JSON']>;
  editorPerLanguage?: InputMaybe<Scalars['Boolean']>;
  excluded?: InputMaybe<Array<Scalars['String']>>;
  generalEditorId?: InputMaybe<Scalars['ID']>;
  included?: InputMaybe<Array<Scalars['String']>>;
  integration?: InputMaybe<IntegrationEnum>;
  keyValueData?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  plainText?: InputMaybe<Scalars['Boolean']>;
  proposedClientDeadline?: InputMaybe<Scalars['String']>;
  purchaseOrderCode?: InputMaybe<Scalars['String']>;
  quality: QualityEnum;
  quote?: InputMaybe<Scalars['Boolean']>;
  sendCompletionEmail?: InputMaybe<Scalars['Boolean']>;
  sourceLanguageCode: Scalars['String'];
  styleGuideReferenceId?: InputMaybe<Scalars['ID']>;
  targetLanguageCodes: Array<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  uploadedFileIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type TranslationRequestOrderDetails = {
  __typename?: 'TranslationRequestOrderDetails';
  details?: Maybe<Array<Detail>>;
  detailsTotal?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of TranslationRequestOrder */
export type TranslationRequestOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  translationRequestVersion: Scalars['ID'];
};

/** Autogenerated return type of TranslationRequestOrder. */
export type TranslationRequestOrderPayload = {
  __typename?: 'TranslationRequestOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  purchaseOrder?: Maybe<PurchaseOrder>;
  success: Scalars['Boolean'];
};

export type TranslationRequestPage = {
  __typename?: 'TranslationRequestPage';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  nodes: Array<TranslationRequest>;
  nodesCount: Scalars['Int'];
  pagesCount: Scalars['Int'];
};

export enum TranslationRequestStatusEnum {
  Adjourned = 'adjourned',
  Canceled = 'canceled',
  Finished = 'finished',
  InTranslation = 'in_translation',
  PaymentFailed = 'payment_failed',
  Pending = 'pending',
  Unordered = 'unordered',
  WaitingForClient = 'waiting_for_client'
}

export enum TranslationRequestStatusFilterEnum {
  Adjourned = 'adjourned',
  Failed = 'failed',
  Finished = 'finished',
  InProgress = 'in_progress',
  ReadyToReview = 'ready_to_review',
  Unordered = 'unordered'
}

export enum TranslationRequestTypeEnum {
  Duplicate = 'duplicate',
  ImmediateOrder = 'immediate_order',
  Io = 'io',
  Nntc = 'nntc',
  NoNewTranslatableContent = 'no_new_translatable_content',
  Quote = 'quote',
  Unknown = 'unknown'
}

export type TranslatorAccount = TranslatorInterface & {
  __typename?: 'TranslatorAccount';
  activity: TranslatorAccountActivityEnum;
  admin: Scalars['Boolean'];
  billingAccount: BillingAccount;
  countryCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  translationBackground: Scalars['JSON'];
  type: TranslatorTypeEnum;
  updatedAt: Scalars['ISO8601DateTime'];
  user?: Maybe<User>;
};

export enum TranslatorAccountActivityEnum {
  Active = 'active',
  Changeable = 'changeable',
  Inactive = 'inactive',
  Unknown = 'unknown'
}

export type TranslatorInterface = {
  createdAt: Scalars['ISO8601DateTime'];
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  type: TranslatorTypeEnum;
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum TranslatorTypeEnum {
  MachineTranslator = 'MachineTranslator',
  TranslatorAccount = 'TranslatorAccount'
}

/** Autogenerated input type of TwoFactor */
export type TwoFactorInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  phoneNumber: Scalars['String'];
};

/** Autogenerated return type of TwoFactor. */
export type TwoFactorPayload = {
  __typename?: 'TwoFactorPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type UniversalChatContextType = {
  __typename?: 'UniversalChatContextType';
  id: Scalars['ID'];
  uploadedFile: ContextLibraryUploadedFileType;
};

export enum UniversalChatEngineEnum {
  Anthropic = 'anthropic',
  ChatGpt = 'chat_gpt',
  ChatGpt4 = 'chat_gpt4',
  ChatGpt4o = 'chat_gpt4o',
  ChatGpto1Mini = 'chat_gpto1_mini',
  ChatGpto1Preview = 'chat_gpto1_preview',
  Tolq_3b = 'tolq_3b'
}

/** Autogenerated input type of UniversalChatMessageCreate */
export type UniversalChatMessageCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  contextIds?: InputMaybe<Array<Scalars['ID']>>;
  file?: InputMaybe<Scalars['Upload']>;
  message: UniversalChatMessageInput;
  model: UniversalChatEngineEnum;
  threadId?: InputMaybe<Scalars['ID']>;
  urls?: InputMaybe<Array<Scalars['String']>>;
};

/** Autogenerated return type of UniversalChatMessageCreate. */
export type UniversalChatMessageCreatePayload = {
  __typename?: 'UniversalChatMessageCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
  thread?: Maybe<Thread>;
};

/** Autogenerated input type of UniversalChatMessageDestroy */
export type UniversalChatMessageDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  messageId: Scalars['ID'];
  threadId: Scalars['ID'];
};

/** Autogenerated return type of UniversalChatMessageDestroy. */
export type UniversalChatMessageDestroyPayload = {
  __typename?: 'UniversalChatMessageDestroyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type UniversalChatMessageInput = {
  changed?: InputMaybe<Scalars['Boolean']>;
  content: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  role: UniversalChatRoleEnum;
};

export enum UniversalChatRoleEnum {
  Assistant = 'assistant',
  User = 'user'
}

/** Autogenerated input type of UniversalChatThreadDestroy */
export type UniversalChatThreadDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  threadId: Scalars['ID'];
};

/** Autogenerated return type of UniversalChatThreadDestroy. */
export type UniversalChatThreadDestroyPayload = {
  __typename?: 'UniversalChatThreadDestroyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UniversalChatThreadUpdate */
export type UniversalChatThreadUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  threadId: Scalars['ID'];
  title: Scalars['String'];
};

/** Autogenerated return type of UniversalChatThreadUpdate. */
export type UniversalChatThreadUpdatePayload = {
  __typename?: 'UniversalChatThreadUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export enum UnsubscribeChannelEnum {
  Marketing = 'marketing'
}

/** Autogenerated input type of Unsubscribe */
export type UnsubscribeInput = {
  channel: UnsubscribeChannelEnum;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
};

/** Autogenerated return type of Unsubscribe. */
export type UnsubscribePayload = {
  __typename?: 'UnsubscribePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateDialects */
export type UpdateDialectsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  dialects: Scalars['JSON'];
};

/** Autogenerated return type of UpdateDialects. */
export type UpdateDialectsPayload = {
  __typename?: 'UpdateDialectsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of Update. */
export type UpdatePayload = {
  __typename?: 'UpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
  user: User;
};

/** Autogenerated input type of Upload */
export type UploadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  csvDelimiter?: InputMaybe<Scalars['String']>;
  file: Scalars['Upload'];
};

/** Autogenerated return type of Upload. */
export type UploadPayload = {
  __typename?: 'UploadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
  uid?: Maybe<Scalars['String']>;
};

export type UploadedFile = {
  __typename?: 'UploadedFile';
  filename?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  accessKey?: Maybe<Scalars['String']>;
  admin: Scalars['Boolean'];
  canDeleteAccount: Scalars['Boolean'];
  email: Scalars['String'];
  emailPreferences?: Maybe<EmailNotificationPreferences>;
  firstName?: Maybe<Scalars['String']>;
  gravatarEmailHash?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  impersonatingUser?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumberVerificationCodeSentCount: Scalars['Int'];
  provider?: Maybe<Scalars['String']>;
  role?: Maybe<RoleEnum>;
  timeZone?: Maybe<Scalars['String']>;
  translationRequestCount?: Maybe<Scalars['Int']>;
  twoFactorAuthenticationEnabled: Scalars['Boolean'];
  unconfirmedEmail?: Maybe<Scalars['String']>;
  verifiedPhoneNumber?: Maybe<Scalars['String']>;
};

export type UserLocalSession = {
  __typename?: 'UserLocalSession';
  authToken?: Maybe<Scalars['String']>;
};
